import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';
import { APIValidationError } from '../../types/api/api';
import { Organizacion } from '../../types/organizaciones/organizacion';

export const getOrganizacionById = async (
  id: UUID,
): Promise<Result<Organizacion, APIValidationError>> => {
  return validatedApiCall<Organizacion>(`/api/organizaciones/${id}`, {
    method: 'GET',
    auth: true,
  });
};
