import { APIValidationError } from '../../types/api/api';
import {
  CodigoPostal,
  CodigoPostalId,
  DNI,
  Email,
  ISO8601DateString,
  UUID,
} from '../../types/standard';
import { Gender } from '../../types/users/gender';
import { UserStates } from '../../types/users/state';
import { Vecino } from '../../types/users/users';
import { Result, validatedApiCall } from '../api';

export type RegisterVecinoDTO = {
  nombre: string;
  apellido1: string;
  apellido2: string;
  telefono: string;
  email: Email;
  dni: DNI;
  calle: string;
  numero: string;
  piso: string;
  codigo_postal: CodigoPostal;
  codigo_postal_id: CodigoPostalId;
  provincia: string;
  organizacion_id: UUID;
  genero: Gender;
  fecha_nacimiento: ISO8601DateString;
  is_empresa: boolean;
  cif_empresa: string | null;
  nombre_empresa: string | null;
  direccion_empresa: string | null;
  aceptar_pp: boolean;
  user_states?: UserStates[];
};

export const registerVecino = async (
  data: RegisterVecinoDTO,
): Promise<Result<Vecino, APIValidationError>> => {
  return validatedApiCall<Vecino>(`/api/vecinos`, {
    method: 'POST',
    auth: false,
    body: JSON.stringify(data),
  });
};
