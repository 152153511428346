import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethod, SetupIntentResult } from '@stripe/stripe-js';
import Stripe from 'stripe';
import { updateSetupIntent as callUpdateSetupIntent } from '../../../api/stripe/bridge/setupIntents';
import { createSetupIntent as callCreateSetupIntent } from '../../../api/stripe/override/setupIntents';
import { Await } from '../../../types/api/api';
import getStripe from '../../../utils/get-stripe';
import { selectAuthData } from '../../auth/selectors';
import { selectSetupIntent } from './selectors';
import setupIntentSlice from './setupIntentSlice';
import { isApiError } from '../../../api/api';
import { t } from '../../../i18n';

function* createSetupIntent(): Generator<any, void, any> {
  try {
    const user = yield select(selectAuthData);
    const result = (yield call(callCreateSetupIntent, user.id)) as Await<
      ReturnType<typeof callCreateSetupIntent>
    >;
    switch (result.type) {
      case 'ok':
        yield put(setupIntentSlice.actions.createSetupIntentOk(result.value));
        return;
      case 'validation-error':
        yield put(setupIntentSlice.actions.createSetupIntentKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(setupIntentSlice.actions.createSetupIntentKo(e));
    }
    throw e;
  }
}

function* addPaymentMethod(
  action: PayloadAction<PaymentMethod>,
): Generator<any, void, any> {
  try {
    const setupIntent = yield select(selectSetupIntent);
    const result = (yield call(callUpdateSetupIntent, {
      setup_intent_id: setupIntent.id,
      data: { payment_method: action.payload.id },
    })) as Await<ReturnType<typeof callUpdateSetupIntent>>;

    let confirmResult: Await<SetupIntentResult>;
    switch (result.type) {
      case 'ok':
        if (action.payload.type === 'card') {
          confirmResult = yield call(confirmCard, result.value);
        } else if (action.payload.type === 'sepa_debit') {
          confirmResult = yield call(confirmSepaDebit, result.value);
        } else {
          yield put(
            setupIntentSlice.actions.addPaymentMethodKo({
              errorType: 'unprocessable',
              message: t('MESSAGE_METODO_NO_SOPORTADO'),
            }),
          );
          return;
        }
        if (confirmResult.error) {
          yield put(setupIntentSlice.actions.addPaymentMethodKo(confirmResult));
          return;
        }
        yield put(
          setupIntentSlice.actions.addPaymentMethodOk(
            confirmResult.setupIntent as Stripe.SetupIntent,
          ),
        );
        return;
      case 'validation-error':
        yield put(setupIntentSlice.actions.addPaymentMethodKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(setupIntentSlice.actions.addPaymentMethodKo(e));
    }
    throw e;
  }
}

async function confirmCard(
  setupIntent: Stripe.SetupIntent,
): Promise<SetupIntentResult> {
  const stripe = await getStripe();
  if (stripe && setupIntent.client_secret) {
    return await stripe.confirmCardSetup(setupIntent.client_secret);
  }
  return {
    error: {
      type: 'api_connection_error',
      message: t('MESSAGE_STRIPE_NO_DISPONIBLE'),
    },
  };
}

async function confirmSepaDebit(
  setupIntent: Stripe.SetupIntent,
): Promise<SetupIntentResult> {
  const stripe = await getStripe();
  if (stripe && setupIntent.client_secret) {
    return stripe.confirmSepaDebitSetup(setupIntent.client_secret);
  }
  return {
    error: {
      type: 'api_connection_error',
      message: t('MESSAGE_STRIPE_NO_DISPONIBLE'),
    },
  };
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    setupIntentSlice.actions.createSetupIntent.type,
    createSetupIntent,
  ),
  takeLatest<PayloadAction<never>>(
    setupIntentSlice.actions.addPaymentMethod.type,
    addPaymentMethod,
  ),
];

export default sagas;
