import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Kpi } from '../../types/kpis/Kpis';
import { ISO8601DateString, UUID } from '../../types/standard';
import toQueryParams from '../../utils/toQueryParams';

export interface GetKpi {
  fecha_inicio?: ISO8601DateString;
  fecha_fin?: ISO8601DateString;
  organizacion_id?: UUID;
  provincia?: string;
  buscar_poblacion?: string;
}

export interface KpisCollection {
  data: Kpi[];
}

export const getKpis = async (
  data: GetKpi,
): Promise<Result<KpisCollection, APIValidationError>> => {
  return validatedApiCall<KpisCollection>(
    `/api/kpis?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
