import { PayloadAction } from '@reduxjs/toolkit';
import { updateOrganizacion as callUpdateOrganizacion } from '../../api/organizaciones/updateOrganizacion';
import { GenericState } from '../../types/apiModule';
import { Organizacion } from '../../types/organizaciones/organizacion';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listOrganizacionesRefresh } from './listOrganizacionModule';
import { t } from '../../i18n';

export interface updateOrganizacionState extends GenericState<unknown> {
  active: boolean;
  organizacion: Organizacion;
}
const initialState: updateOrganizacionState = {
  errors: null,
  data: [],
  loading: false,
  active: false,
  organizacion: {} as Organizacion,
};

const updateOrganizacionModule = createApiModule({
  name: 'updateOrganizacion',
  initialState,
  apiMethod: callUpdateOrganizacion,
  onSuccess: (organizacion) => [
    showSnackBar({
      severity: 'success',
      message: t('StoreEntidadPrincipalCambiadaCorrectamente', {
        nombre: organizacion.nombre,
      }),
    }),
    listOrganizacionesRefresh(),
  ],
  onError: () => ({
    message: t(
      'StoreOrganizacionesUpdateOrganizacionModuleErrorModificandoLaOrganizacion',
    ),
  }),
  onValidationError: () => ({
    message: t('MESSAGE_ERROR_CAMBIAR_ENTIDAD'),
  }),
  reducers: {
    show(state, action: PayloadAction<Organizacion>) {
      state.active = true;
      state.organizacion = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: updateOrganizacion,
  success: updateOrganizacionOk,
  error: updateOrganizacionKo,
  hide,
  show,
} = updateOrganizacionModule.slice.actions;

export const { sagas } = updateOrganizacionModule;

export default updateOrganizacionModule;
