import { postVoluntarioAcuerdo } from '../../api/voluntarios/postVoluntarioAcuerdo';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const uploadVoluntarioAcuerdoModule = createApiModule({
  name: 'uploadVoluntarioAcuerdo',
  apiMethod: postVoluntarioAcuerdo,
  onSuccess: () => ({
    message: t('StoreVoluntariosVoluntarioAcuerdoVoluntarioGuardado'),
  }),
  onError: () => ({
    message: t(
      'StoreVoluntariosUploadVoluntarioAcuerdoModuleErrorAlGuardarElAcuerdoDeVoluntario',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreVoluntariosUploadVoluntarioAcuerdoModuleErrorAlGuardarElAcuerdoDeVoluntario',
    ),
  }),
});

export const {
  start: uploadVoluntarioAcuerdo,
  success: uploadVoluntarioAcuerdoOk,
  error: uploadVoluntarioAcuerdoKo,
} = uploadVoluntarioAcuerdoModule.slice.actions;

export default uploadVoluntarioAcuerdoModule;
