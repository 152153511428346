import { getValoracion as callGetValoracion } from '../../api/valoraciones/getValoracion';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const getValoracionModule = createApiModule({
  name: 'valoracion',
  apiMethod: callGetValoracion,
  parse: (data) => data,
  onError: () => ({
    message: t(
      'StoreValoracionesGetValoracionModuleNoHemosPodidoRecuperarLaValoracion',
    ),
  }),
  onSuccess: () => {},
});

export const {
  start: getValoracion,
  success: getValoracionOk,
  error: getValoracionKo,
  refresh: getValoracionRefresh,
} = getValoracionModule.slice.actions;

export default getValoracionModule;
