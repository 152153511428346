import { PayloadAction } from '@reduxjs/toolkit';
import { deleteOrganizacion } from '../../api/organizaciones/deleteOrganizacion';
import { GenericState } from '../../types/apiModule';
import { Organizacion } from '../../types/organizaciones/organizacion';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listOrganizacionesRefresh } from './listOrganizacionModule';
import { t } from '../../i18n';
export interface deleteOrganizacionState extends GenericState<unknown> {
  active: boolean;
  data: Organizacion[];
  selectedOrganizacion: Organizacion | null;
}
const initialState: deleteOrganizacionState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  selectedOrganizacion: null,
};

const deleteOrganizacionModule = createApiModule({
  name: 'deleteOrganizacion',
  initialState,
  apiMethod: deleteOrganizacion,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_ORGANIZACION_ELIMINADA'),
    }),
    listOrganizacionesRefresh(),
  ],
  onError: () => ({
    message: t(
      'StoreOrganizacionesDeleteOrganizacionModuleErrorBorrandoLaOrganizacion',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreOrganizacionesDeleteOrganizacionModuleErrorBorrandoLaOrganizacion',
    ),
  }),
  reducers: {
    show(state, action: PayloadAction<Organizacion>) {
      state.active = true;
      state.selectedOrganizacion = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: del,
  success: delOk,
  error: delKo,
  hide,
  show,
} = deleteOrganizacionModule.slice.actions;

export default deleteOrganizacionModule;
