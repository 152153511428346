import { getRecursoURL } from '../../api/recursos/getRecursoURL';
import { Recurso } from '../../types/recursos/Recurso';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const downloadRecursoModule = createApiModule({
  name: 'downloadRecurso',
  initialState: {
    loading: false,
    errors: null,
    data: {} as Recurso,
  },
  apiMethod: getRecursoURL,
  onSuccess: (recurso) => {
    if (recurso.url) {
      window.open(recurso.url);
    }
  },
  onError: () => ({
    message: t(
      'StoreDownloadRecursoModuleSeHaProducidoUnErrorEnLaDescargaDelRecurso',
    ),
  }),
  parse: (recurso) => recurso,
});

export const { start: download } = downloadRecursoModule.slice.actions;

export default downloadRecursoModule;
