import {
  sendSolicitud as sendSolicitudes,
  SendSolicitudDTO,
} from '../../api/solicitudes/sendSolicitud';
import { GenericState } from '../../types/apiModule';
import { Solicitud } from '../../types/solicitud/solicitud';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { getVoluntarioEstado } from '../voluntarios/voluntarioEstadoModule';
import { t } from '../../i18n';
export interface sendSolicitudState extends GenericState<unknown> {
  solicitud: Solicitud;
  data: SendSolicitudDTO;
}
const initialState: sendSolicitudState = {
  errors: null,
  data: { tipo: '' },
  loading: false,
  solicitud: {} as Solicitud,
};

const sendSolicitudModule = createApiModule({
  name: 'sendSolicitud',
  initialState,
  apiMethod: sendSolicitudes,
  onSuccess: (solicitud) => [
    showSnackBar({
      severity: 'success',
      message: t('StoreVoluntariosVoluntarioBajaSolicitud'),
      path: '/',
    }),
    getVoluntarioEstado({}),
  ],
  onError: () => ({
    message: t('StoreSolicitudSendSolicitudModuleErrorEnviandoLaSolicitud'),
  }),
  onValidationError: () => ({
    message: t('StoreSolicitudSendSolicitudModuleErrorEnviandoLaSolicitud'),
  }),
});

export const {
  start: sendSolicitud,
  success: sendSolicitudOk,
  error: sendSolicitudKo,
} = sendSolicitudModule.slice.actions;

export default sendSolicitudModule;
