import { getCertificadoURL } from '../../api/certificados/certificados';
import { Certificado } from '../../types/certificado/certificado';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const downloadCertificadoModule = createApiModule({
  name: 'downloadCertificado',
  initialState: {
    loading: false,
    errors: null,
    data: {} as Certificado,
  },
  apiMethod: getCertificadoURL,
  onSuccess: (certificado) => {
    if (certificado.url) {
      window.open(certificado.url);
    }
  },
  onError: () => ({
    message: t(
      'StoreCertificadoDownloadCertificadoModuleSeHaProducidoUnErrorEnLaDescargaDelCertificado',
    ),
  }),
  parse: (certificado) => certificado,
});

export const { start: download } = downloadCertificadoModule.slice.actions;

export default downloadCertificadoModule;
