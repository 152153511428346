import {
  DonacionesDTO,
  getDonaciones,
} from '../../api/donaciones/getDonaciones';
import { GenericState } from '../../types/apiModule';
import { Donacion } from '../../types/donaciones/donaciones';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';
export interface DonacionesModule extends GenericState<unknown> {
  data: DonacionesDTO;
}

const initialState: DonacionesModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Donacion[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listDonacionesModule = createApiModule({
  name: 'listDonaciones',
  apiMethod: getDonaciones,
  initialState,
  parse: (donaciones) => donaciones,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreDonacionesLisDonacionesModuleErrorListandoLasDonaciones'),
  }),
  onValidationError: () => {},
});

export const {
  start: listDonaciones,
  success: listDonacionesOk,
  error: listDonacionesKo,
  refresh: listDonacionesRefresh,
  reset: listDonacionesReset,
} = listDonacionesModule.slice.actions;

export const { sagas } = listDonacionesModule;

export default listDonacionesModule;
