import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import actividadModule from './actividad/actividadModule';
import deleteActividadModule from './actividad/deleteActividadModule';
import listActividadModule from './actividad/listActividadModule';
import postActividadModule from './actividad/postActividadModule';
import alertsModule from './alerts/alertsModule';
import deleteAlertsModule from './alerts/deleteAlertsModule';
import newAlertsModule from './alerts/newAlertsModule';
import readAlertsModule from './alerts/readAlertsModule';
import authSlice from './auth/authSlice';
import restrictionsReducer from './auth/restrictions/restrictionsReducer';
import downloadCertificadoModule from './certificado/downloadCertificadoModule';
import listComunicacionModule from './comunicacion/ListComunicacionModule';
import deleteDonacionModule from './donaciones/deleteDonacionModule';
import listDonacionesModule from './donaciones/lisDonacionesModule';
import registerDonacionSlice from './donaciones/registerDonacionSlice';
import donacionExternaModule from './donacionExterna/donacionExternaModule';
import listDonacionesOwnModule from './donacionOwn/listDonacionesOwnModule';
import deleteEntidadModule from './entidades/deleteEntidadModule';
import entidadModule from './entidades/entidadModule';
import listEntidadModule from './entidades/listEntidadModule';
import registerEntidadModule from './entidades/registerEntidadModule';
import externalDonatorModule from './externalDonators/externalDonatorModule';
import facturacionModule from './facturacion/facturacionModule';
import changePasswordModule from './forgotPassword/changePasswordModule';
import sendPasswordModule from './forgotPassword/sendPasswordModule';
import kpisModule from './kpis/kpisModule';
import poblacionesSlice from './poblacion/poblacionesSlice';
import deleteOrganizacionModule from './organizaciones/deleteOrganizacionModule';
import listOrganizacionModule from './organizaciones/listOrganizacionModule';
import organizacionModule from './organizaciones/organizacionModule';
import registerOrganizacionModule from './organizaciones/registerOrganizacionModule';
import updateOrganizacionModule from './organizaciones/updateOrganizacionModule';
import rememberPathSlice from './rememberPath/rememberPathSlice';
import rootSaga from './root-saga';
import listSesionModule from './sesion/listSesionModule';
import registerSesionModule from './sesion/registerSesionModule';
import snackBarSlice from './snackBar/snackBarSlice';
import changeEstadoSolicitudModule from './solicitud/changeEstadoSolicitudModule';
import listSolicitudModule from './solicitud/listSolicitudModule';
import registerSolicitudModule from './solicitud/registerSolicitudModule';
import sendSolicitudModule from './solicitud/sendSolicitudModule';
import solicitudModule from './solicitud/solicitudModule';
import checkoutSessionSlice from './stripe/checkoutSession/checkoutSessionSlice';
import customerSlice from './stripe/customer/customerSlice';
import listPaymentMethodsModule from './stripe/customer/listPaymentMethods';
import paymentMethodSlice from './stripe/paymentMethod/paymentMethodSlice';
import productsModule from './stripe/products/productsModule';
import setupIntentSlice from './stripe/setupIntent/setupIntentSlice';
import deleteUserModule from './users/deleteUserModule';
import listUserModule from './users/listUserModule';
import registerUserModule from './users/registerUserModule';
import updateUserModule from './users/updateUserModule';
import userModule from './users/userModule';
import bajaVecinoModule from './users/vecinos/bajaVecinoModule';
import bajaVecinoRestrictionsModule from './users/vecinos/bajaVecinoRestrictionsModule';
import registerVecinoSlice from './users/vecinos/registerVecinoSlice';
import getValoracionModule from './valoraciones/getValoracionModule';
import registerValoracionModule from './valoraciones/registerValoracionModule';
import updateValoracionModule from './valoraciones/updateValoracionModule';
import changeOrganizacionModule from './vecinos/changeOrganizacionModule';
import deleteVecinoModule from './vecinos/deleteVecinoModule';
import deleteVecinoSocioModule from './vecinos/deleteVecinoSocioModule';
import getVecinoSocioModule from './vecinos/getVecinoSocioModule';
import listVecinoModule from './vecinos/listVecinosModule';
import registerVecinoSocioSlice from './vecinos/registerVecinoSocioSlice';
import updateVecinoModule from './vecinos/updateVecinoModule';
import updateDatosPersonalesModule from './perfil/updateDatosPersonalesModule';
import deleteVoluntarioModule from './voluntarios/deleteVoluntarioModule';
import listVoluntariosModule from './voluntarios/ListVoluntariosModule';
import voluntarioBajaModule from './voluntarios/voluntarioBajaModule';
import voluntarioEstadoModule from './voluntarios/voluntarioEstadoModule';
import voluntarioDisponibilidadModule from './voluntarios/voluntarioDisponibilidadModule';
import updateVoluntarioDisponibilidadModule from './voluntarios/updateVoluntarioDisponibilidadModule';
import voluntarioAcuerdoModule from './voluntarios/voluntarioAcuerdoModule';
import uploadVoluntarioAcuerdoModule from './voluntarios/uploadVoluntarioAcuerdoModule';
import perfilVoluntarioModule from './voluntarios/perfilVoluntarioModule';
import postPerfilVoluntarioModule from './voluntarios/postPerfilVoluntarioModule';
import updateVoluntarioAcuerdoModule from './voluntarios/updateVoluntarioAcuerdoModule';
import listRecursoModule from './recursos/listRecursoModule';
import uploadRecursoModule from './recursos/uploadRecursoModule';

import showVoluntarioModule from './voluntarios/showVoluntarioModule';
import {
  createRouterMiddleware,
  createRouterReducer,
} from '@lagunovsky/redux-react-router';
import cancelAssociateSubscriptionModule from './vecinos/cancelAssociateSubscription';
import downloadRecursoModule from './recursos/downloadRecursoModule';
import deleteRecursoModule from './recursos/deleteRecursoModule';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(history);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'authRestrictions', 'registerVecino'],
};

const appReducer = combineReducers({
  router: createRouterReducer(history),
  snackBar: snackBarSlice.reducer,

  auth: authSlice.reducer,
  authRestrictions: restrictionsReducer,
  sendPassword: sendPasswordModule.slice.reducer,
  updatePassword: changePasswordModule.slice.reducer,

  user: userModule.slice.reducer,
  users: listUserModule.slice.reducer,
  registerUser: registerUserModule.slice.reducer,
  deleteUser: deleteUserModule.slice.reducer,
  updateUser: updateUserModule.slice.reducer,

  alerts: alertsModule.slice.reducer,
  readAlerts: readAlertsModule.slice.reducer,
  deleteAlerts: deleteAlertsModule.slice.reducer,
  newAlerts: newAlertsModule.slice.reducer,

  poblaciones: poblacionesSlice.reducer,

  registerOrganizacion: registerOrganizacionModule.slice.reducer,
  organizaciones: listOrganizacionModule.slice.reducer,
  organizacion: organizacionModule.slice.reducer,
  deleteOrganizacion: deleteOrganizacionModule.slice.reducer,
  updateOrganizacion: updateOrganizacionModule.slice.reducer,

  externalDonator: externalDonatorModule.slice.reducer,

  registerEntidad: registerEntidadModule.slice.reducer,
  entidades: listEntidadModule.slice.reducer,
  entidad: entidadModule.slice.reducer,
  deleteEntidad: deleteEntidadModule.slice.reducer,

  checkoutSession: checkoutSessionSlice.reducer,
  donacionExterna: donacionExternaModule.slice.reducer,
  registerVecino: registerVecinoSlice.reducer,
  products: productsModule.slice.reducer,

  donaciones: listDonacionesModule.slice.reducer,
  donacionesOwn: listDonacionesOwnModule.slice.reducer,
  certificados: downloadCertificadoModule.slice.reducer,

  vecinos: listVecinoModule.slice.reducer,
  deleteVecino: deleteVecinoModule.slice.reducer,
  updateVecino: updateVecinoModule.slice.reducer,
  updateDatosPersonales: updateDatosPersonalesModule.slice.reducer,
  vecinoSocio: getVecinoSocioModule.slice.reducer,
  registerSocio: registerVecinoSocioSlice.reducer,
  deleteSocio: deleteVecinoSocioModule.slice.reducer,
  cancelAssociateSubscription: cancelAssociateSubscriptionModule.slice.reducer,

  deleteDonacion: deleteDonacionModule.slice.reducer,
  changeOrganizacionVecino: changeOrganizacionModule.slice.reducer,
  registerDonacion: registerDonacionSlice.reducer,

  listPaymentMethods: listPaymentMethodsModule.slice.reducer,

  setupIntent: setupIntentSlice.reducer,
  paymentMethod: paymentMethodSlice.reducer,
  customer: customerSlice.reducer,

  facturacion: facturacionModule.slice.reducer,

  solicitud: solicitudModule.slice.reducer,
  solicitudes: listSolicitudModule.slice.reducer,
  registerSolicitud: registerSolicitudModule.slice.reducer,
  sendSolicitud: sendSolicitudModule.slice.reducer,
  changeEstadoSoliciutd: changeEstadoSolicitudModule.slice.reducer,

  voluntarios: listVoluntariosModule.slice.reducer,
  registerValoracion: registerValoracionModule.slice.reducer,
  valoracion: getValoracionModule.slice.reducer,
  bajaRestrictions: bajaVecinoRestrictionsModule.slice.reducer,
  baja: bajaVecinoModule.slice.reducer,
  updateValoracion: updateValoracionModule.slice.reducer,

  registerSesion: registerSesionModule.slice.reducer,
  listSesiones: listSesionModule.slice.reducer,

  voluntarioEstado: voluntarioEstadoModule.slice.reducer,
  voluntarioDisponibilidad: voluntarioDisponibilidadModule.slice.reducer,
  voluntarioAcuerdo: voluntarioAcuerdoModule.slice.reducer,
  updateVoluntarioAcuerdo: updateVoluntarioAcuerdoModule.slice.reducer,
  updateVoluntarioDisponibilidad:
    updateVoluntarioDisponibilidadModule.slice.reducer,
  uploadVoluntarioAcuerdo: uploadVoluntarioAcuerdoModule.slice.reducer,
  perfilVoluntario: perfilVoluntarioModule.slice.reducer,
  postPerfilVoluntario: postPerfilVoluntarioModule.slice.reducer,
  voluntarioBaja: voluntarioBajaModule.slice.reducer,
  voluntarioDelete: deleteVoluntarioModule.slice.reducer,
  voluntario: showVoluntarioModule.slice.reducer,

  comunicaciones: listComunicacionModule.slice.reducer,

  rememberPath: rememberPathSlice.reducer,

  postActividad: postActividadModule.slice.reducer,
  listActividad: listActividadModule.slice.reducer,
  deleteActividad: deleteActividadModule.slice.reducer,
  actividad: actividadModule.slice.reducer,
  updateActividad: actividadModule.slice.reducer,

  listKpis: kpisModule.slice.reducer,
  listRecurso: listRecursoModule.slice.reducer,
  uploadRecurso: uploadRecursoModule.slice.reducer,
  downloadRecurso: downloadRecursoModule.slice.reducer,
  deleteRecurso: deleteRecursoModule.slice.reducer,
});

/**
 * Reducer that wraps the regular app reducer to guarantee
 * than all of the app's state gets reset when the user logs
 * out of the application.
 *
 * @param state application state
 * @param action action that has been perfomed
 * @return next state
 */
const rootReducer: typeof appReducer = (state, action): RootState => {
  if (action.type === authSlice.actions.logout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pReducer,
  middleware: [sagaMiddleware, routerMiddleware] as const, // prevent this from becoming just `Array<Middleware>`
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export const persistor = persistStore(store);

export default store;
