import { APIValidationError } from '../../types/api/api';
import { Recurso } from '../../types/recursos/Recurso';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';

export const getRecursoURL = async (
  id: UUID,
): Promise<Result<Recurso, APIValidationError>> => {
  return validatedApiCall<Recurso>(`/api/recursos/${id}`, {
    method: 'GET',
    auth: true,
  });
};
