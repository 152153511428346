import { registerSolicitud } from '../../api/solicitudes/registerSolicitud';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const registerSolicitudModule = createApiModule({
  name: 'registerSolicitud',
  apiMethod: registerSolicitud,
  onSuccess: (solicitud) => ({
    message: t('RoutesHomeHomeConfiguraGraciasPorTuSolicitud'),
    autoHideDuration: 8000,
    path: '/acompanamiento',
  }),
  onError: () => ({
    message: t('StoreSolicitudSendSolicitudModuleErrorEnviandoLaSolicitud'),
  }),
  onValidationError: () => ({
    message: t('StoreSolicitudSendSolicitudModuleErrorEnviandoLaSolicitud'),
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerSolicitudModule.slice.actions;

export default registerSolicitudModule;
