import { patchVoluntarioAcuerdo } from '../../api/voluntarios/patchVoluntarioAcuerdo';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const updateVoluntarioAcuerdoModule = createApiModule({
  name: 'updateVoluntarioAcuerdo',
  apiMethod: patchVoluntarioAcuerdo,
  parse: (voluntarioAcuerdo) => voluntarioAcuerdo,
  onSuccess: () => ({
    message: t('StoreVoluntariosVoluntarioAcuerdoVoluntarioGuardado'),
  }),
  onError: () => ({
    message: t(
      'StoreVoluntariosUploadVoluntarioAcuerdoModuleErrorAlGuardarElAcuerdoDeVoluntario',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreVoluntariosUploadVoluntarioAcuerdoModuleErrorAlGuardarElAcuerdoDeVoluntario',
    ),
  }),
});

export const {
  start: updateVoluntarioAcuerdo,
  success: updateVoluntarioAcuerdoOk,
  error: updateVoluntarioAcuerdoKo,
} = updateVoluntarioAcuerdoModule.slice.actions;

export default updateVoluntarioAcuerdoModule;
