import { markAlertsAsRead } from '../../api/alerts/markAlertsAsRead';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listAlerts } from './alertsModule';
import { t } from '../../i18n';

const readAlertsModule = createApiModule({
  name: 'readAlerts',
  apiMethod: markAlertsAsRead,
  parse: (response) => response.data,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_ALERTAS_MARCADAS_LEIDAS'),
    }),
    listAlerts({ page: 1, size: 10 }),
  ],
  onError: () => ({
    message: t(
      'StoreAlertsReadAlertsModuleNoHemosPodidoMarcarComoLeidasLasAlertas',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreAlertsReadAlertsModuleNoHemosPodidoMarcarComoLeidasLasAlertas',
    ),
  }),
});

export const {
  start: readAlerts,
  success: readAlertsOk,
  error: readAlertsKo,
} = readAlertsModule.slice.actions;

export const { sagas } = readAlertsModule;

export default readAlertsModule;
