import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as IconLogout } from '../../../../assets/icon_logout.svg';
import logoPobles from '../../../../assets/logo_pobles.svg';

import MenuGestion from '../Menu/Menu';

import { logout } from '../../../../store/auth/authSlice';
import { selectAuthAvatar } from '../../../../store/auth/selectors';
import { AppDispatch, RootState } from '../../../../store/store';
import PopupActions from '../../../PopupActions/PopupActions';
import SnackBar from '../../SnackBar/SnackBar';
import styles from './layout.module.scss';
import { Outlet } from 'react-router-dom';
import LanguageSwitch from '../../LanguageSwitch/LanguageSwitch';

const makeStyleRoute = (location: string) => (path: string) => {
  if (location === '/' && path === location) {
    return `${styles.menuItem} ${styles.isCurrentPage}`;
  } else if (location.startsWith(path) && path !== '/') {
    return `${styles.menuItem} ${styles.isCurrentPage}`;
  } else {
    return `${styles.menuItem}`;
  }
};

interface LayoutProps {
  userAvatar: string | null;
  styleRoute: (route: string) => string;
  logout: () => void;
}

const Layout: React.FC<LayoutProps> = ({ userAvatar, styleRoute, logout }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const { t } = useTranslation();

  return (
    <div className={`${styles.root} `}>
      <header className={styles.header}>
        <Link className={styles.logoLink} to="/">
          <img
            className={styles.logoPoblesImg}
            src={logoPobles}
            alt="Plataforma Digital de 'Pobles que cuiden'"
          />
        </Link>
        <div className={styles.headerLeft}>
          <LanguageSwitch mode={'admin'} />
          <ul className={styles.headerMenu}>
            <li className={styles.headerMenuItem}>
              <button
                className={styles.headerMenuButton}
                onClick={() => logout()}
              >
                <IconLogout className={styles.headerMenuIcon} />
                {t('CERRAR_SESION')}
              </button>
            </li>
          </ul>
        </div>
      </header>
      <MenuGestion
        isOpenMenu={isOpenMenu}
        setIsOpenMenu={setIsOpenMenu}
        styleRoute={styleRoute}
        userAvatar={userAvatar}
      />
      <main className={`${styles.mainContent}`}>
        <Outlet />
      </main>
      <PopupActions />
      <SnackBar />
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    styleRoute: makeStyleRoute(location.pathname),
    userAvatar: selectAuthAvatar(state),
  }),
  (dispatch: AppDispatch) => ({
    logout: () => {
      dispatch(logout());
    },
  }),
)(Layout);
