import { getOrganizacionById } from '../../api/organizaciones/getOrganizacionById';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const organizacionModule = createApiModule({
  name: 'organizacion',
  apiMethod: getOrganizacionById,
  initialData: null,
  parse: (organizacion) => organizacion,
  onSuccess: () => {},
  onError: () => ({
    message: t(
      'StoreOrganizacionesOrganizacionModuleNoHemosPodidoCargarLaOrganizacion',
    ),
    path: '/organizaciones',
  }),
  onValidationError: () => ({
    message: t(
      'StoreOrganizacionesOrganizacionModuleNoHemosPodidoCargarLaOrganizacion',
    ),
    path: '/organizaciones',
  }),
});

export const {
  start: getOrganizacion,
  success: getOrganizacionOk,
  error: getOrganizacionKo,
  reset: resetOrganizacion,
} = organizacionModule.slice.actions;

export default organizacionModule;
