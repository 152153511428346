import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakeSocioDTO, SocioDTO } from '../../api/vecinos/vecinoSocio';
import { APIError } from '../../types/api/api';
import { Socio } from '../../types/socio/socio';

export interface RegisterVecinoSocioState {
  registerData: SocioDTO | null;
  socio: Socio | null;
  loading: boolean;
  errors: APIError | null;
  confirmActive: boolean;
}

const initialState: RegisterVecinoSocioState = {
  registerData: null,
  socio: null,
  loading: false,
  errors: null,
  confirmActive: false,
};

const registerVecinoSocioSlice = createSlice({
  name: 'registerVecinoSocio',
  initialState: initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    register(state, action: PayloadAction<SocioDTO>) {
      state.registerData = action.payload;
      state.loading = true;
    },
    registerOk(state, action: PayloadAction<MakeSocioDTO>) {
      state.loading = false;
      state.socio = action.payload;
    },
    registerKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
    selectPriceCooperativa(state, action: PayloadAction<SocioDTO>) {
      state.registerData = action.payload;
      state.confirmActive = true;
    },
    selectPriceOrganizacion(state, action: PayloadAction<SocioDTO>) {
      state.registerData = action.payload;
      state.confirmActive = true;
    },
    unselectPrice(state) {
      state.registerData = null;
    },
    hideConfirm(state) {
      state.confirmActive = false;
    },
  },
});

export const {
  register,
  registerOk,
  registerKo,
  selectPriceCooperativa,
  selectPriceOrganizacion,
  unselectPrice,
  hideConfirm,
} = registerVecinoSocioSlice.actions;

export default registerVecinoSocioSlice;
