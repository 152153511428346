import {
  getOrganizaciones,
  OrganizacionesDTO,
} from '../../api/organizaciones/getOrganizaciones';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Organizacion } from '../../types/organizaciones/organizacion';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

export interface OrganizacionModule extends GenericState<unknown> {
  data: OrganizacionesDTO;
}

const initialState: OrganizacionModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Organizacion[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listOrganizacionModule = createApiModule({
  name: 'listOrganizaciones',
  apiMethod: getOrganizaciones,
  initialState,
  parse: (organizaciones) => organizaciones,
  onSuccess: () => {},
  onError: () => ({
    message: t(
      'StoreOrganizacionesListOrganizacionModuleErrorListandoLasOrganizaciones',
    ),
  }),
  onValidationError: () => {},
});

export const {
  start: listOrganizaciones,
  success: listOrganizacionesOk,
  error: listOrganizacionesKo,
  refresh: listOrganizacionesRefresh,
  reset: listOrganizacionesReset,
} = listOrganizacionModule.slice.actions;

export const { sagas } = listOrganizacionModule;

export default listOrganizacionModule;
