import { Result, validatedApiCall } from '../api';
import { RegisterOrganizacionDTO } from '../../types/organizaciones/register';
import { APIValidationError } from '../../types/api/api';
import { Organizacion } from '../../types/organizaciones/organizacion';

export const registerOrganizacion = async (
  data: RegisterOrganizacionDTO,
): Promise<Result<Organizacion, APIValidationError>> => {
  return validatedApiCall<Organizacion>(`/api/organizaciones`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
