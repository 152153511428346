import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const List = lazy(() => import('./List'));
const Register = lazy(() => import('./Register'));

const OrganizacionRoutes = [
  {
    path: 'organizaciones',
    element: (
      <PrivateRoute
        allowedPermissions={['read_organizacion']}
        component={List}
      />
    ),
  },
  {
    path: 'organizacion/nuevo',
    element: (
      <PrivateRoute
        allowedPermissions={['create_organizacion']}
        component={Register}
      />
    ),
  },
];

export default OrganizacionRoutes;
