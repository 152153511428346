import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { UpdateOrganizacionDTO } from '../../../api/organizaciones/updateOrganizacion';
import { ReactComponent as IconReverse } from '../../../assets/moreMenu/reverse.svg';
import renderWhen from '../../../helpers/renderWhen';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import {
  listEntidades,
  listEntidadesReset,
} from '../../../store/entidades/listEntidadModule';
import {
  selectEntidades,
  selectEntidadesLoading,
} from '../../../store/entidades/selectors';
import {
  getOrganizacion,
  resetOrganizacion,
} from '../../../store/organizaciones/organizacionModule';
import {
  selectOrganizacion,
  selectUpdateOrganizacionActive,
  selectUpdateOrganizacionErrors,
  selectUpdateOrganizacionLoading,
  selectUpdateOrganizacionOrganizacion,
} from '../../../store/organizaciones/selectors';
import {
  hide,
  updateOrganizacion,
} from '../../../store/organizaciones/updateOrganizacionModule';
import { AppDispatch, RootState } from '../../../store/store';
import { APIError } from '../../../types/api/api';
import { Entidad } from '../../../types/entidad/Entidad';
import { Organizacion } from '../../../types/organizaciones/organizacion';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import GlobalError from '../../common/Form/GlobalError/GlobalError';
import ValidatedSelect from '../../common/Form/ValidatedSelect/ValidatedSelect';
import FormLayout from '../../common/Layouts/FormLayout/FormLayout';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { t } from '../../../i18n';

const changeEntidadPrincipalSchema = yup
  .object()
  .shape({
    entidad: yup.object().shape({
      id: yup.string().required(t('ValidarSeleccionaNuevaEntidadPrincipal')),
    }),
  })
  .defined();

interface ChangeEntidadPrincipalOrganizacionProps {
  organizacion: Organizacion;
  organizacionWithEntidad: Organizacion | null;
  entidades: Entidad[];
  serverErrors: APIError | null;
  active: boolean;
  loading: boolean;
  loadingEntidades: boolean;
  loadOrganizacion: (x: UUID) => void;
  resetOrganizacion: () => void;
  closeModal: () => void;
  updateOrganizacion: (x: UpdateOrganizacionDTO) => void;
  loadEntidades: (organizacion_id: UUID) => void;
  resetEntidades: () => void;
}

const ChangeEntidadPrincipalOrganizacion: React.FC<
  ChangeEntidadPrincipalOrganizacionProps
> = ({
  organizacion,
  organizacionWithEntidad,
  entidades,
  serverErrors,
  active,
  loading,
  loadingEntidades,
  loadOrganizacion,
  resetOrganizacion,
  closeModal,
  updateOrganizacion,
  loadEntidades,
  resetEntidades,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    formState,
  } = useForm<UpdateOrganizacionDTO>({
    mode: 'onSubmit',
    resolver: yupResolver(changeEntidadPrincipalSchema),
  });
  useEffect(() => {
    resetOrganizacion();
    resetEntidades();
  }, [resetOrganizacion, resetEntidades]);
  useEffect(() => {
    if (!organizacionWithEntidad) {
      loadOrganizacion(organizacion.id);
      loadEntidades(organizacion.id);
    }
  }, [
    organizacionWithEntidad,
    loadOrganizacion,
    loadEntidades,
    resetEntidades,
    organizacion,
    resetOrganizacion,
  ]);

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  // 'active' controls if the modal is open or close
  if (!active) return null;

  const changeEntidadPrincipalSubmit = (data: UpdateOrganizacionDTO) => {
    data.id = organizacion.id;
    updateOrganizacion(data);
    closeModal();
  };

  return (
    <>
      {organizacion && organizacionWithEntidad && (
        <Modal variant="large" closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconReverse className={`${styles.icon} ${styles.isIconTrash}`} />
              {t(
                'ComponentsPopupActionsChangeEntidadPrincipalOrganizacionChangeEntidadPrincipalOrganizacionCambiarEntidadPrincipal',
              )}
            </h1>
            <p className={styles.description}>
              {t('LaEntidadPrincipalDeXEsY', {
                organizacion_nombre: organizacionWithEntidad.nombre,
                entidad_nombre:
                  organizacionWithEntidad.entidad_principal?.nombre,
              })}
            </p>
            <div className={styles.appointmentWrapper}>
              <FormLayout
                variant={'profile'}
                onSubmit={handleSubmit(changeEntidadPrincipalSubmit)}
              >
                <ValidatedSelect
                  schema={changeEntidadPrincipalSchema}
                  errors={errors}
                  label={t(
                    'ComponentsPopupActionsChangeEntidadPrincipalOrganizacionChangeEntidadPrincipalOrganizacionNuevaEntidadPrincipal',
                  )}
                  placeholder={t(
                    'ComponentsPopupActionsChangeEntidadPrincipalOrganizacionChangeEntidadPrincipalOrganizacionSeleccionaUnaEntidad',
                  )}
                  select={{
                    ...register('entidad.id'),
                    name: 'entidad.id',
                  }}
                >
                  {!loadingEntidades &&
                    entidades.map((entidad) => (
                      <React.Fragment key={entidad.id}>
                        {entidad.id !==
                          organizacionWithEntidad.entidad_principal?.id && (
                          <option key={entidad.id} value={entidad.id}>
                            {entidad.nombre}
                          </option>
                        )}
                      </React.Fragment>
                    ))}
                </ValidatedSelect>
                <ButtonsGroup variant="profile">
                  <React.Fragment>
                    <Button
                      type="button"
                      variant="negative"
                      onClick={closeModal}
                    >
                      {t('CommonCerrar')}
                    </Button>
                    <Button variant="positive">{t('CommonGuardar')}</Button>
                  </React.Fragment>
                </ButtonsGroup>
              </FormLayout>
            </div>
            {/* Server-side global error */}
            {globalError && <GlobalError message={globalError} />}

            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedChangeEntidadPrincipalOrganizacion = connect(
  (state: RootState) => ({
    organizacion: selectUpdateOrganizacionOrganizacion(state),
    organizacionWithEntidad: selectOrganizacion(state),
    active: selectUpdateOrganizacionActive(state),
    loading: selectUpdateOrganizacionLoading(state),
    serverErrors: selectUpdateOrganizacionErrors(state),
    entidades: selectEntidades(state),
    loadingEntidades: selectEntidadesLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    loadOrganizacion: (payload: UUID) => dispatch(getOrganizacion(payload)),
    resetOrganizacion: () => dispatch(resetOrganizacion()),
    updateOrganizacion: (payload: UpdateOrganizacionDTO) =>
      dispatch(updateOrganizacion(payload)),
    closeModal: () => dispatch(hide()),
    loadEntidades: (organizacion_id: UUID) =>
      dispatch(listEntidades({ organizacion: organizacion_id, size: 999 })),
    resetEntidades: () => dispatch(listEntidadesReset()),
  }),
)(ChangeEntidadPrincipalOrganizacion);

export default renderWhen(
  ConnectedChangeEntidadPrincipalOrganizacion,
  selectUpdateOrganizacionActive,
);
