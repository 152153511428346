import { getSolicitudById } from '../../api/solicitudes/getSolicitudById';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const solicitudModule = createApiModule({
  name: 'solicitud',
  apiMethod: getSolicitudById,
  initialData: null,
  parse: (solicitud) => solicitud,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreSolicitudSolicitudModuleNoHemosPodidoCargarLaSolicitud'),
    path: '/',
  }),
  onValidationError: () => ({
    message: t('StoreSolicitudSolicitudModuleNoHemosPodidoCargarLaSolicitud'),
    path: '/',
  }),
});

export const {
  start: getSolicitud,
  success: getSolicitudOk,
  error: getSolicitudKo,
  reset: resetSolicitud,
} = solicitudModule.slice.actions;

export default solicitudModule;
