import {
  getSolicitudes,
  SolicitudDTO,
} from '../../api/solicitudes/getSolicitudes';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Solicitud } from '../../types/solicitud/solicitud';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';
export interface SolicitudModule extends GenericState<unknown> {
  data: SolicitudDTO;
}

const initialState: SolicitudModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Solicitud[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listSolicitudModule = createApiModule({
  name: 'listSolicitudes',
  apiMethod: getSolicitudes,
  initialState,
  parse: (solicitud) => solicitud,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreSolicitudListSolicitudModuleErrorListandoLasSolicitudes'),
  }),
  onValidationError: () => {},
});

export const {
  start: listSolicitudes,
  success: listSolicitudesOk,
  error: listSolicitudesKo,
  refresh: listSolicitudesRefresh,
  reset: listSolicitudesReset,
} = listSolicitudModule.slice.actions;

export default listSolicitudModule;
