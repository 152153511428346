import {
  ListActividad,
  listActividad as listActividades,
} from '../../api/actividad/listActividad';
import { Actividad } from '../../types/actividad/Actividad';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';
export interface ListActividadModule extends GenericState<unknown> {
  data: ListActividad;
}

const initialState: ListActividadModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Actividad[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listActividadModule = createApiModule({
  name: 'listActividad',
  apiMethod: listActividades,
  initialState,
  parse: (actividades) => actividades,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreActividadListActividadModuleErrorListandoLasActividades'),
  }),
  onValidationError: () => {},
});

export const {
  start: listActividad,
  success: listActividadOk,
  error: listActividadKo,
  refresh: listActividadRefresh,
  reset: listActividadReset,
} = listActividadModule.slice.actions;

export const { sagas } = listActividadModule;

export default listActividadModule;
