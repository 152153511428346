import { registerOrganizacion } from '../../api/organizaciones/registerOrganizacion';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const registerOrganizacionModule = createApiModule({
  name: 'registerOrganizacion',
  apiMethod: registerOrganizacion,
  onSuccess: (organizacion) => ({
    message: t('StoreOrganizacionCreadaCorrectamente', {
      nombre: organizacion.nombre,
    }),
    path: '/organizaciones',
  }),
  onError: () => ({
    message: t(
      'StoreOrganizacionesRegisterOrganizacionModuleErrorCreandoLaOrganizacion',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreOrganizacionesRegisterOrganizacionModuleErrorCreandoLaOrganizacion',
    ),
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerOrganizacionModule.slice.actions;

export const { sagas } = registerOrganizacionModule;

export default registerOrganizacionModule;
