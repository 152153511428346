import { getEntidadById } from '../../api/entidades/getEntidadById';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const entidadModule = createApiModule({
  name: 'entidad',
  apiMethod: getEntidadById,
  initialData: null,
  parse: (entidad) => entidad,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreEntidadesEntidadModuleNoHemosPodidoCargarLaEntidad'),
    path: '/entidades',
  }),
  onValidationError: () => ({
    message: t('StoreEntidadesEntidadModuleNoHemosPodidoCargarLaEntidad'),
    path: '/entidades',
  }),
});

export const {
  start: getEntidad,
  success: getEntidadOk,
  error: getEntidadKo,
  reset: resetEntidad,
} = entidadModule.slice.actions;

export default entidadModule;
