import { sendPassword } from '../../api/forgotPassword/sendPassword';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { t } from '../../i18n';

const sendPasswordModule = createApiModule({
  name: 'sendPassword',
  apiMethod: sendPassword,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_EMAIL_INSTRUCCIONES_ENVIADO'),
      path: '/login',
    }),
  ],
  onError: () => ({
    message: t('StoreForgotPasswordSendPasswordModuleElEmailNoExiste'),
  }),
  onValidationError: () => ({
    message: t('StoreForgotPasswordSendPasswordModuleElEmailNoExiste'),
  }),
});

export const {
  start: send,
  success: sendPasswordOk,
  error: sendPasswordKo,
} = sendPasswordModule.slice.actions;

export default sendPasswordModule;
