import { APIValidationError } from '../../types/api/api';
import { Recurso } from '../../types/recursos/Recurso';
import { Result, validatedApiCall } from '../api';
import { UploadFileDTO } from '../uploads/UploadFileDTO';

export const postRecurso = async (
  payload: UploadFileDTO,
): Promise<Result<Recurso, APIValidationError>> => {
  const dataForm = new FormData();
  dataForm.append('fichero', payload.data.file, payload.data.file.name);
  return validatedApiCall<Recurso>(`/api/recursos`, {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  });
};
