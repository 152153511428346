import {
  ListRecursoDTO,
  listRecurso as listRecursos,
} from '../../api/recursos/listRecurso';
import { Recurso } from '../../types/recursos/Recurso';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';
export interface ListRecursoModule extends GenericState<unknown> {
  data: ListRecursoDTO;
}

const initialState: ListRecursoModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Recurso[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listRecursoModule = createApiModule({
  name: 'listRecurso',
  apiMethod: listRecursos,
  initialState,
  parse: (recursos) => recursos,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreListRecursoModuleErrorListandoLosRecursos'),
  }),
  onValidationError: () => ({
    message: t('StoreListRecursoModuleErrorListandoLosRecursos'),
  }),
});

export const {
  start: listRecurso,
  success: listRecursoOk,
  error: listRecursoKo,
  refresh: listRecursoRefresh,
  reset: listRecursoReset,
} = listRecursoModule.slice.actions;

export const { sagas } = listRecursoModule;

export default listRecursoModule;
