import { getUsers, UsersDTO } from '../../api/users/getUsers';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { User } from '../../types/users/users';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';
export interface UserModule extends GenericState<unknown> {
  data: UsersDTO;
}

const initialState: UserModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as User[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listUserModule = createApiModule({
  name: 'listUsers',
  apiMethod: getUsers,
  initialState,
  parse: (users) => users,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreUsersListUserModuleErrorListandoLosUsuariosAs'),
  }),
  onValidationError: () => ({
    message: t('StoreUsersListUserModuleErrorListandoLosUsuariosAs'),
  }),
});

export const {
  start: listUsers,
  success: listUsersOk,
  error: listUsersKo,
  refresh: listUsersRefresh,
  reset: listUsersReset,
} = listUserModule.slice.actions;

export default listUserModule;
