import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import {
  Address,
  AddressFloor,
  AddressNumber,
  UUID,
} from '../../types/standard';
import { User } from '../../types/users/users';

export interface ChangeOrganizacionDTO {
  user_id: UUID;
  organizacion_id: UUID;
  calle: Address;
  numero: AddressNumber;
  piso: AddressFloor;
}

export const changeOrganizacion = async (
  data: ChangeOrganizacionDTO,
): Promise<Result<User, APIValidationError>> => {
  return validatedApiCall<User>(
    `/api/vecinos/${data.user_id}/change_organizacion`,
    {
      method: 'PUT',
      auth: true,
      body: JSON.stringify(data),
    },
  );
};
