import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const List = lazy(() => import('./List'));
const View = lazy(() => import('./View'));
const Register = lazy(() => import('./Register'));

const ListAcompanamiento = lazy(() => import('./Acompanamiento/List'));
const ListAcompanamientoResponsive = lazy(
  () => import('./Acompanamiento/ListResponsive'),
);

const isMobile = window.innerWidth < 1024;

const SolicitudesRoutes = [
  {
    path: 'solicitudes',
    children: [
      {
        index: true,
        element: (
          <PrivateRoute
            allowedPermissions={['read_all_solicitud']}
            component={List}
          />
        ),
      },
      {
        path: ':id',
        element: (
          <PrivateRoute
            allowedPermissions={['read_all_solicitud']}
            component={View}
          />
        ),
      },
    ],
  },
  {
    path: 'acompanamiento',
    children: [
      {
        index: true,
        element: (
          <PrivateRoute
            allowedPermissions={['read_solicitud']}
            component={
              !isMobile ? ListAcompanamiento : ListAcompanamientoResponsive
            }
          />
        ),
      },
      {
        path: 'nuevo',
        element: (
          <PrivateRoute
            allowedPermissions={['create_solicitud']}
            component={Register}
          />
        ),
      },
      {
        path: ':id',
        element: (
          <PrivateRoute
            allowedPermissions={['read_solicitud']}
            component={View}
          />
        ),
      },
    ],
  },
];

export default SolicitudesRoutes;
