import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { TipoActividad } from '../../types/actividad/type';
import { ISO8601DateString, UUID } from '../../types/standard';
import { Actividad } from '../../types/actividad/Actividad';

export interface ListActividadParams {
  page?: number;
  size?: number;
  tipo?: TipoActividad | '';
  nombre?: string;
  fecha_inicio?: ISO8601DateString;
  fecha_fin?: ISO8601DateString;
  organizacion_id?: UUID;
}

export interface ListActividad {
  data: Actividad[];
  links: LinksPage;
  meta: PageInfo;
}

export const listActividad = async (
  data: ListActividadParams,
): Promise<Result<ListActividad, APIValidationError>> => {
  return validatedApiCall<ListActividad>(
    `/api/actividad?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
