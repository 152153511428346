import { PayloadAction } from '@reduxjs/toolkit';
import { deleteSocio } from '../../api/vecinos/vecinoSocio';
import { GenericState } from '../../types/apiModule';
import { Socio } from '../../types/socio/socio';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { getVecinoSocio } from './getVecinoSocioModule';
import { t } from '../../i18n';

export interface deleteVecinoSocioState extends GenericState<unknown> {
  active: boolean;
  data: Socio | null;
}
const initialState: deleteVecinoSocioState = {
  errors: null,
  loading: false,
  active: false,
  data: null,
};

const deleteVecinoSocioModule = createApiModule({
  name: 'deleteVecinoSocio',
  initialState,
  apiMethod: deleteSocio,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_SUSCRIPCION_CANCELADA'),
    }),
    getVecinoSocio({}),
  ],
  onError: () => ({
    message: t(
      'StoreVecinosDeleteVecinoSocioModuleSeHaProducidoUnErrorAlCancelarTuSuscripcion',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreVecinosDeleteVecinoSocioModuleSeHaProducidoUnErrorAlCancelarTuSuscripcion',
    ),
  }),
  reducers: {
    show(state, action: PayloadAction<Socio>) {
      state.active = true;
      state.data = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: deleteVecinoSocio,
  success: deleteVecinoSocioOk,
  error: deleteVecinoSocioKo,
  hide: hideDeleteVecinoSocio,
  show: showDeleteVecinoSocio,
} = deleteVecinoSocioModule.slice.actions;

export default deleteVecinoSocioModule;
