import { APIValidationError } from '../../types/api/api';
import { VoluntarioAcuerdo } from '../../types/voluntarios/VoluntarioAcuerdo';
import { Result, validatedApiCall } from '../api';
import { UploadFileDTO } from '../uploads/UploadFileDTO';

export const postVoluntarioAcuerdo = async (
  payload: UploadFileDTO,
): Promise<Result<VoluntarioAcuerdo, APIValidationError>> => {
  const dataForm = new FormData();
  dataForm.append('acuerdo', payload.data.file, payload.data.file.name);
  if (payload.id) {
    dataForm.append('solicitud_id', payload.id);
  }
  return validatedApiCall<VoluntarioAcuerdo>(`/api/voluntarios/acuerdo`, {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  });
};
