import { updateVecino } from '../../api/vecinos/updateVecino';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const updateDatosPersonalesModule = createApiModule({
  name: 'updateDatosPersonales',
  apiMethod: updateVecino,
  onSuccess: (user) => ({
    message: t('MESSAGE_DATOS_MODIFICADOS'),
    path: '/',
  }),
  onError: () => ({
    message: t(
      'StorePerfilUpdateDatosPersonalesModuleErrorAlModificarTusDatos',
    ),
  }),
  onValidationError: () => ({
    message: 'Error al modficar tus datos',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateDatosPersonalesModule.slice.actions;

export default updateDatosPersonalesModule;
