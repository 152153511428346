import { deleteRecurso as deleteRecursoAPI } from '../../api/recursos/deleteRecurso';
import { Recurso } from '../../types/recursos/Recurso';
import createApiModule from '../createApiModule';
import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState } from '../../types/apiModule';
import { t } from '../../i18n';

export interface deleteRecursoState extends GenericState<unknown> {
  active: boolean;
  data: Recurso | null;
}
const initialState: deleteRecursoState = {
  errors: null,
  loading: false,
  active: false,
  data: null,
};

const deleteRecursoModule = createApiModule({
  name: 'deleteRecurso',
  initialState,
  apiMethod: deleteRecursoAPI,
  onSuccess: () => ({
    message: t('StoreDeleteRecursoModuleOk'),
  }),
  onError: () => ({
    message: t('StoreDeleteRecursoModuleError'),
  }),
  onValidationError: () => ({
    message: t('StoreDeleteRecursoModuleError'),
  }),
  reducers: {
    show(state, action: PayloadAction<Recurso>) {
      state.active = true;
      state.data = action.payload;
    },
    hide(state) {
      state.active = false;
      state.data = null;
    },
  },
});

export const {
  start: deleteRecurso,
  success: deleteRecursoOk,
  error: deleteRecursoKo,
  hide,
  show,
} = deleteRecursoModule.slice.actions;

export default deleteRecursoModule;
