import Stripe from 'stripe';
import { APIValidationError } from '../../types/api/api';
import { CuotaPeriodicidad } from '../../types/donaciones/cuotaPeriodicidad.d';
import { DonacionDestino } from '../../types/donaciones/donacionDestino.d';
import { ISO8601DateTimeString, UUID } from '../../types/standard';
import { User } from '../../types/users/users';
import { Result, validatedApiCall } from '../api';

export interface SubscriptionDTO {
  destino: DonacionDestino;
  periodicidad: CuotaPeriodicidad;
  importe: number;
  organizacion_id?: UUID;
  price: Stripe.Price;
  created_at?: ISO8601DateTimeString;
  updated_at?: ISO8601DateTimeString;
  deleted_at?: ISO8601DateTimeString;
}

export interface SubscribeDTO {
  user: User;
  subscription: SubscriptionDTO;
}
export const subscribe = async (
  data: SubscribeDTO,
): Promise<Result<SubscriptionDTO, APIValidationError>> => {
  const subscription = {
    ...data.subscription,
    price_id: data.subscription.price.id,
  };
  return validatedApiCall<SubscriptionDTO>(`/api/subscribe/${data.user.id}`, {
    method: 'POST',
    auth: false,
    body: JSON.stringify(subscription),
  });
};
