import { APIValidationError } from '../../types/api/api';
import { Entidad } from '../../types/entidad/Entidad';
import { TipoEntidad } from '../../types/entidad/tipo_entidad';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Provincia } from '../../types/provincia/provincia';
import { UUID } from '../../types/standard';
import toQueryParams from '../../utils/toQueryParams';
import { Result, validatedApiCall } from '../api';

export interface EntidadesDTO {
  data: Entidad[];
  links: LinksPage;
  meta: PageInfo;
}

export interface GetEntidadDTO {
  page?: number;
  size?: number;
  buscar_nombre?: string | '';
  buscar_poblacion?: string | '';
  provincia?: Provincia | '';
  organizacion?: UUID | '';
  entidadPrincipal?: UUID;
  tipo?: TipoEntidad;
  is_sede_central?: number;
}

export const getEntidades = async (
  data: GetEntidadDTO,
): Promise<Result<EntidadesDTO, APIValidationError>> => {
  return validatedApiCall<EntidadesDTO>(
    `/api/entidades?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
