import { updateEntidad } from '../../api/entidades/updateEntidad';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { resetEntidad } from './entidadModule';
import { t } from '../../i18n';

const updateEntidadModule = createApiModule({
  name: 'updateEntidad',
  apiMethod: updateEntidad,
  onSuccess: (entidad) => [
    resetEntidad(),
    showSnackBar({
      severity: 'success',
      message: t('StoreEntidadModificadaCorrectamente', {
        nombre: entidad.nombre,
      }),
      path: '/entidades',
    }),
  ],

  onError: () => ({
    message: t('StoreEntidadesUpdateEntidadModuleErrorModificandoLaEntidad'),
  }),
  onValidationError: (error) => ({
    message: error.message || 'Error modificando la entidad',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateEntidadModule.slice.actions;

export const { sagas } = updateEntidadModule;

export default updateEntidadModule;
