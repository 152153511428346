import { updateVecino } from '../../api/vecinos/updateVecino';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const updateVecinoModule = createApiModule({
  name: 'updateVecino',
  apiMethod: updateVecino,
  onSuccess: (user) => ({
    message: t('StoreUsuarioModificado', {
      nombre: user.nombre,
      apellido1: user.apellido1,
      apellido2: user.apellido2,
    }),
    path: '/vecinos',
  }),
  onError: () => ({
    message: t('StoreVecinosUpdateVecinoModuleErrorAlModificarElVecinoA'),
  }),
  onValidationError: () => ({
    message: 'Error al modficar el vecino/a',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateVecinoModule.slice.actions;

export default updateVecinoModule;
