import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreatePaymentMethodData } from '@stripe/stripe-js';
import Stripe from 'stripe';
import { SubscriptionDTO } from '../../../api/subscriptions/subscribe';
import { RegisterVecinoDTO } from '../../../api/users/registerVecino';
import { APIError } from '../../../types/api/api';
import { UUID } from '../../../types/standard';
import { Vecino } from '../../../types/users/users';
import { t } from '../../../i18n';

export interface SocioTipo {
  socio_tipo: 'organizacion' | 'cooperativa';
  price: Stripe.Price | null;
  organizacion_id?: UUID;
}

export type RegisterStep =
  | 'register'
  | 'socio'
  | 'voluntario-cuidador'
  | 'metodo-pago'
  | 'completado';

export interface VecinoSocio {
  es_socio: boolean | undefined;
  datos_socio: SocioTipo | undefined;
  setup_intent: Stripe.SetupIntent | null;
  payment_method: Stripe.PaymentMethod | null;
  loading_setup_intent: boolean;
  subscription: SubscriptionDTO | null;
  errors: APIError | null;
}

export type RegisterVecinoState = {
  form_data: RegisterVecinoDTO | null;
  vecino: Vecino | null;
  socio: VecinoSocio;
  voluntario: boolean | undefined;
  persona_cuidadora: boolean | undefined;
  loading_register: boolean;
  errors: APIError | null;
  registro_completado: boolean;
  step: RegisterStep;
};

const initialState: RegisterVecinoState = {
  form_data: null,
  vecino: null,
  socio: {
    es_socio: undefined,
    datos_socio: undefined,
    setup_intent: null,
    payment_method: null,
    loading_setup_intent: false,
    errors: null,
    subscription: null,
  },
  voluntario: undefined,
  persona_cuidadora: undefined,
  errors: null,
  loading_register: false,
  registro_completado: false,
  step: 'register',
};

const registerVecinoSlice = createSlice({
  name: 'registerVecino',
  initialState: initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    storeFormData(state, action: PayloadAction<RegisterVecinoDTO>) {
      state.form_data = action.payload;
      state.step = 'socio';
    },
    register(state, action: PayloadAction<RegisterVecinoDTO>) {
      state.loading_register = true;
      state.form_data = action.payload;
    },
    registerOk(state, action: PayloadAction<Vecino>) {
      state.vecino = action.payload;
      state.loading_register = false;
      state.step = state.socio.es_socio ? 'metodo-pago' : 'completado';
    },
    registerKo(state, action: PayloadAction<APIError>) {
      state.loading_register = false;
      state.errors = action.payload;
      state.step = 'register';
    },
    resetSocio(state) {
      state.socio.es_socio = undefined;
      state.socio.datos_socio = undefined;
    },
    storeNoSocio(state) {
      state.socio.es_socio = false;
      state.socio.datos_socio = undefined;
    },
    storeSocio(state, action: PayloadAction<SocioTipo>) {
      if (
        action.payload.socio_tipo === 'organizacion' &&
        !action.payload.organizacion_id
      ) {
        state.errors = {
          errorType: 'validation',
          message: t('MESSAGE_INDICAR_ORGANIZACION'),
          errors: { organizacion_id: ['Se requiere un organizacion'] },
        };
      } else {
        state.socio.es_socio = true;
        state.socio.datos_socio = action.payload;
      }
    },
    createSetupIntent(state) {
      if (!state.vecino) {
        state.socio.errors = {
          errorType: 'validation',
          message: t('RoutesHomeHomeConfiguraMetodoPagoRegistroNecesario'),
          errors: { organizacion_id: ['Se requiere un vecino/a'] },
        };
      } else {
        if (!state.socio.setup_intent) {
          state.socio.loading_setup_intent = true;
        }
      }
    },
    createSetupIntentOk(state, action: PayloadAction<Stripe.SetupIntent>) {
      state.socio.loading_setup_intent = false;
      state.socio.setup_intent = action.payload;
      state.socio.errors = null;
    },
    createSetupIntentKo(state, action: PayloadAction<APIError>) {
      state.socio.loading_setup_intent = false;
      state.socio.setup_intent = null;
      state.socio.errors = action.payload;
    },
    confirmSetupIntent(state, data: PayloadAction<Object | undefined>) {
      state.socio.loading_setup_intent = true;
      state.socio.errors = null;
    },
    confirmSetupIntentOk(state, action: PayloadAction<Stripe.SetupIntent>) {
      state.socio.setup_intent = action.payload;
      state.socio.loading_setup_intent = false;
    },
    confirmSetupIntentKo(state, action: PayloadAction<APIError>) {
      state.socio.loading_setup_intent = false;
      state.socio.errors = action.payload;
    },
    storeSuscriptionOk(state, action: PayloadAction<SubscriptionDTO>) {
      state.socio.loading_setup_intent = false;
      state.socio.subscription = action.payload;
      state.step = 'completado';
    },
    storeSuscriptionKo(state, action: PayloadAction<APIError>) {
      state.socio.errors = action.payload;
      state.socio.loading_setup_intent = false;
    },
    updateSetupIntentOk(state, action: PayloadAction<Stripe.SetupIntent>) {
      state.socio.setup_intent = action.payload;
      state.socio.errors = null;
    },
    updateSetupIntentKo(state, action: PayloadAction<APIError>) {
      state.socio.loading_setup_intent = false;
      state.socio.errors = action.payload;
    },
    createPaymentMethod(state, action: PayloadAction<CreatePaymentMethodData>) {
      state.socio.payment_method = null;
      state.socio.errors = null;
    },
    createPaymentMethodOk(state, action: PayloadAction<Stripe.PaymentMethod>) {
      state.socio.loading_setup_intent = true;
      state.socio.payment_method = action.payload;
    },
    createPaymentMethodKo(state, action: PayloadAction<APIError>) {
      state.socio.loading_setup_intent = false;
      state.socio.payment_method = null;
      state.socio.errors = action.payload;
    },
    storeVoluntario(state) {
      state.voluntario = true;
      if (state.form_data) {
        if (!state.form_data.user_states) {
          state.form_data.user_states = ['voluntario'];
        } else if (!state.form_data.user_states.includes('voluntario')) {
          state.form_data.user_states = [
            ...state.form_data.user_states,
            'voluntario',
          ];
        }
      }
    },
    storeNoVoluntario(state) {
      state.voluntario = false;
      if (state.form_data) {
        if (
          state.form_data.user_states &&
          state.form_data.user_states.includes('voluntario')
        ) {
          state.form_data.user_states = state.form_data.user_states.filter(
            (state) => {
              return state !== 'voluntario' ? state : null;
            },
          );
        }
      }
    },
    storePersonaCuidadora(state) {
      state.persona_cuidadora = true;
      if (state.form_data) {
        if (!state.form_data.user_states) {
          state.form_data.user_states = ['cuidador'];
        } else if (!state.form_data.user_states.includes('cuidador')) {
          state.form_data.user_states = [
            ...state.form_data.user_states,
            'cuidador',
          ];
        }
      }
    },
    storeNoPersonaCuidadora(state) {
      state.persona_cuidadora = false;
      if (state.form_data) {
        if (
          state.form_data.user_states &&
          state.form_data.user_states.includes('cuidador')
        ) {
          state.form_data.user_states = state.form_data.user_states.filter(
            (state) => {
              return state !== 'cuidador' ? state : null;
            },
          );
        }
      }
    },
    finishRegister(state) {
      state.loading_register = true;
    },
    storeStep(state, action: PayloadAction<RegisterStep>) {
      state.step = action.payload;
    },
  },
});

export const {
  storeFormData,
  register,
  reset,
  resetSocio,
  storeSocio,
  storeNoSocio,
  createSetupIntent,
  confirmSetupIntent,
  createPaymentMethod,
  storeVoluntario,
  storeNoVoluntario,
  storePersonaCuidadora,
  storeNoPersonaCuidadora,
  storeStep,
} = registerVecinoSlice.actions;

export default registerVecinoSlice;
