import React from 'react';
import ChangeEntidadPrincipalOrganizacion from './ChangeEntidadPrincipalOrganizacion/ChangeEntidadPrincipalOrganizacion';
import ChangeOrganizacionVecino from './ChangeOrganizacionVecino/ChangeOrganizacionVecino';
import DeleteActividad from './DeleteActividad/DeleteActividad';
import DeleteEntidad from './DeleteEntidad/DeleteEntidad';
import DeleteOrganizacion from './DeleteOrganizacion/DeleteOrganizacion';
import DeleteUser from './DeleteUser/DeleteUser';
import DeleteVecino from './DeleteVecino/DeleteVecino';
import DeleteVoluntario from './DeleteVoluntario/DeleteVoluntario';
import CancelAssociateSubscription from './CancelAssociateSubscription/CancelAssociateSubscription';

const PopupActions = () => {
  return (
    <>
      <DeleteOrganizacion />
      <DeleteEntidad />
      <DeleteUser />
      <ChangeOrganizacionVecino />
      <DeleteVecino />
      <ChangeEntidadPrincipalOrganizacion />
      <DeleteActividad />
      <DeleteVoluntario />
      <CancelAssociateSubscription />
    </>
  );
};

export default PopupActions;
