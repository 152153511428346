import { APIValidationError } from '../../types/api/api';
import { VoluntarioAcuerdo } from '../../types/voluntarios/VoluntarioAcuerdo';
import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';

export const getVoluntarioAcuerdo = async (
  voluntario_id: UUID,
): Promise<Result<VoluntarioAcuerdo, APIValidationError>> => {
  return validatedApiCall<VoluntarioAcuerdo>(
    `/api/voluntarios/voluntario/${voluntario_id}/acuerdo`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
