import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  hide,
  changeOrganizacion,
} from '../../../store/vecinos/changeOrganizacionModule';
import * as yup from 'yup';

import { AppDispatch, RootState } from '../../../store/store';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

import { User } from '../../../types/users/users';
import {
  selectChangeOrganizacionVecinoActive,
  selectChangeOrganizacionVecinoLoading,
  selectChangeOrganizacionVecinoUser,
  selectChangeOrganizacionVecinoErrors,
} from '../../../store/vecinos/selectors';
import { ChangeOrganizacionDTO } from '../../../api/vecinos/changeOrganizacion';
import FormLayout from '../../common/Layouts/FormLayout/FormLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import ValidatedInput from '../../common/Form/ValidatedInput/ValidatedInput';
import {
  listOrganizaciones,
  listOrganizacionesReset,
} from '../../../store/organizaciones/listOrganizacionModule';
import { GetOrganizacionDTO } from '../../../api/organizaciones/getOrganizaciones';
import {
  selectOrganizaciones,
  selectOrganizacionesLoading,
} from '../../../store/organizaciones/selectors';
import { Organizacion } from '../../../types/organizaciones/organizacion';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import { APIError } from '../../../types/api/api';
import GlobalError from '../../common/Form/GlobalError/GlobalError';
import ValidatedSelect from '../../common/Form/ValidatedSelect/ValidatedSelect';
import { useForm } from 'react-hook-form';
import { t } from '../../../i18n';

const changeOrganizacionVecinoSchema = yup
  .object()
  .shape({
    calle: yup.string().required(t('ValidarIntroduceLaCalle')),
    numero: yup.string().required(t('ValidarIntroduceElNumero')),
    organizacion_id: yup
      .string()
      .required(t('ValidarIntroduceUnaOrganizacion')),
  })
  .defined();

interface ChangeOrganizacionVecinoProps {
  user: User;
  organizaciones: Organizacion[];
  serverErrors: APIError | null;
  active: boolean;
  loading: boolean;
  loadingOrganizaciones: boolean;
  closeModal: () => void;
  changeOrganizacion: (id: ChangeOrganizacionDTO) => void;
  loadOrganizaciones: (id: GetOrganizacionDTO) => void;
  resetOrganizaciones: () => void;
}

const ChangeOrganizacionVecino: React.FC<ChangeOrganizacionVecinoProps> = ({
  user,
  organizaciones,
  serverErrors,
  active,
  loading,
  loadingOrganizaciones,
  closeModal,
  changeOrganizacion,
  loadOrganizaciones,
  resetOrganizaciones,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    formState,
  } = useForm<ChangeOrganizacionDTO>({
    mode: 'onSubmit',
    resolver: yupResolver(changeOrganizacionVecinoSchema),
  });

  const changeOrganizacionSubmit = (data: ChangeOrganizacionDTO) => {
    data.user_id = user.id ?? '';
    changeOrganizacion(data);
  };

  useEffect(() => {
    if (organizaciones.length === 0) {
      loadOrganizaciones({ size: 1000 });
    }
  }, [organizaciones, loadOrganizaciones, resetOrganizaciones]);

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  // 'active' controls if the modal is open or close
  if (!active) return null;

  const nombreOrganizacion =
    user.roles && user.roles.vecino && user.roles.vecino.organizacion
      ? user.roles.vecino.organizacion.nombre
      : '';

  const changeOrganizacionVecinoSubmit = (data: ChangeOrganizacionDTO) => {
    changeOrganizacionSubmit(data);
    closeModal();
  };

  return (
    <>
      {user && (
        <Modal closeModal={closeModal} variant="large">
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              {t(
                'ComponentsPopupActionsChangeOrganizacionVecinoChangeOrganizacionVecinoCambiarDeOrganizacion',
              )}
            </h1>
            <p className={styles.description}>
              {t('ElVecinoXEstaEnOrgY', {
                nombre: user.nombre,
                apellido1: user.apellido1,
                apellido2: user.apellido2,
                organizacion: nombreOrganizacion,
              })}
            </p>
            <div className={styles.appointmentWrapper}>
              <FormLayout
                variant={'profile'}
                onSubmit={handleSubmit(changeOrganizacionVecinoSubmit)}
              >
                <ValidatedSelect
                  schema={changeOrganizacionVecinoSchema}
                  errors={errors}
                  label={t(
                    'ComponentsPopupActionsChangeOrganizacionVecinoChangeOrganizacionVecinoOrganizacion',
                  )}
                  placeholder={t(
                    'ComponentsPopupActionsChangeOrganizacionVecinoChangeOrganizacionVecinoSeleccionaUnaOrganizacion',
                  )}
                  select={{
                    ...register('organizacion_id'),
                    name: 'organizacion_id',
                  }}
                >
                  {!loadingOrganizaciones &&
                    organizaciones.map((organizacion) => (
                      <option key={organizacion.id} value={organizacion.id}>
                        {organizacion.nombre}
                      </option>
                    ))}
                </ValidatedSelect>
                <ValidatedInput
                  schema={changeOrganizacionVecinoSchema}
                  errors={errors}
                  label={t(
                    'ComponentsPopupActionsChangeOrganizacionVecinoChangeOrganizacionVecinoCalle',
                  )}
                  input={{
                    ...register('calle'),
                    name: 'calle',
                    type: 'text',
                  }}
                />
                <ValidatedInput
                  schema={changeOrganizacionVecinoSchema}
                  errors={errors}
                  label={t(
                    'ComponentsPopupActionsChangeOrganizacionVecinoChangeOrganizacionVecinoNumero',
                  )}
                  input={{
                    ...register('numero'),
                    name: 'numero',
                    type: 'text',
                  }}
                />
                <ValidatedInput
                  schema={changeOrganizacionVecinoSchema}
                  errors={errors}
                  label={t(
                    'ComponentsPopupActionsChangeOrganizacionVecinoChangeOrganizacionVecinoPiso',
                  )}
                  input={{
                    ...register('piso'),
                    name: 'piso',
                    type: 'text',
                  }}
                />
                <ButtonsGroup variant="profile">
                  <React.Fragment>
                    <Button
                      type="button"
                      variant="negative"
                      onClick={closeModal}
                    >
                      {t('CommonCerrar')}
                    </Button>
                    <Button variant="positive">{t('CommonGuardar')}</Button>
                  </React.Fragment>
                </ButtonsGroup>
              </FormLayout>
            </div>
            {/* Server-side global error */}
            {globalError && <GlobalError message={globalError} />}

            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedChangeOrganizacionVecino = connect(
  (state: RootState) => ({
    user: selectChangeOrganizacionVecinoUser(state),
    active: selectChangeOrganizacionVecinoActive(state),
    loading: selectChangeOrganizacionVecinoLoading(state),
    serverErrors: selectChangeOrganizacionVecinoErrors(state),
    organizaciones: selectOrganizaciones(state),
    loadingOrganizaciones: selectOrganizacionesLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    changeOrganizacion: (payload: ChangeOrganizacionDTO) =>
      dispatch(changeOrganizacion(payload)),
    closeModal: () => dispatch(hide()),
    loadOrganizaciones: (payload: GetOrganizacionDTO) =>
      dispatch(listOrganizaciones(payload)),
    resetOrganizaciones: () => dispatch(listOrganizacionesReset()),
  }),
)(ChangeOrganizacionVecino);

export default renderWhen(
  ConnectedChangeOrganizacionVecino,
  selectChangeOrganizacionVecinoActive,
);
