import { PerfilVoluntario } from '../../types/voluntarios/PerfilVoluntario';
import { getPerfilVoluntario } from '../../api/voluntarios/getPerfilVoluntario';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const perfilVoluntarioModule = createApiModule({
  name: 'perfilVoluntario',
  apiMethod: getPerfilVoluntario,
  parse: (perfilVoluntario) => perfilVoluntario as PerfilVoluntario | null,
  onSuccess: () => {},
  onError: () => ({
    message: t(
      'StoreVoluntariosPerfilVoluntarioModuleNoSeHaPodidoRecuperarTuPerfilDeVoluntario',
    ),
  }),
});

export const {
  start: getPerfil,
  success: getPerfilOk,
  error: getPerfilKo,
} = perfilVoluntarioModule.slice.actions;

export default perfilVoluntarioModule;
