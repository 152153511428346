import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { UUID } from '../../types/standard';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { User } from '../../types/users/users';

export interface GetUsersDTO {
  role?: string;
  organizacion?: UUID;
  buscar?: string;
  page?: number;
  size?: number;
}

export interface UsersDTO {
  data: User[];
  links: LinksPage;
  meta: PageInfo;
}

export const getUsers = async (
  data: GetUsersDTO,
): Promise<Result<UsersDTO, APIValidationError>> => {
  return validatedApiCall<UsersDTO>(
    `/api/users?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
