import { postPerfilVoluntario } from '../../api/voluntarios/postPerfilVoluntario';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const postPerfilVoluntarioModule = createApiModule({
  name: 'postPerfilVoluntario',
  apiMethod: postPerfilVoluntario,
  onSuccess: () => ({
    message: t('StoreVoluntariosVoluntarioBajaPerfil'),
  }),
  onError: () => ({
    message: t(
      'StoreVoluntariosPostPerfilVoluntarioModuleErrorAlGuardarElPerfilDeVoluntario',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreVoluntariosPostPerfilVoluntarioModuleErrorAlGuardarElPerfilDeVoluntario',
    ),
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = postPerfilVoluntarioModule.slice.actions;

export default postPerfilVoluntarioModule;
