import { exportVecino } from '../../api/vecinos/exportVecino';
import { downloadFileFromContent } from '../../helpers/redirects/downloadFileFromContent';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const exportVecinosModule = createApiModule({
  name: 'exportVecinos',
  apiMethod: exportVecino,
  onSuccess: (data) =>
    downloadFileFromContent(
      data.content,
      'vecinos.csv',
      'text/csv;charset=utf-8;',
    ),
  onError: () => ({
    message: t('StoreVecinosExportVecinosModuleErrorExportandoLosVecinos'),
  }),
  onValidationError: () => ({
    message: t('StoreVecinosExportVecinosModuleErrorExportandoLosVecinos'),
  }),
});

export const {
  start: exportVecinos,
  success: exportVecinosOk,
  error: exportVecinosKo,
} = exportVecinosModule.slice.actions;

export default exportVecinosModule;
