import { postActividad } from '../../api/actividad/postActividad';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const postActividadModule = createApiModule({
  name: 'postActividad',
  apiMethod: postActividad,
  onSuccess: () => ({
    message: t('StoreVoluntariosVoluntarioActividadCreadaCorrectamente'),
    path: '/actividades',
  }),
  onError: () => ({
    message: t('StoreActividadPostActividadModuleErrorCreandoLaActivdad'),
  }),
  onValidationError: () => ({
    message: t('MESSAGE_ERROR_CREANDO_ACTIVIDAD'),
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = postActividadModule.slice.actions;

export const { sagas } = postActividadModule;

export default postActividadModule;
