import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { DNI, Phone, UUID } from '../../types/standard';
import { Role } from '../../types/auth/roles';
import { User } from '../../types/users/users';

export interface UpdateUserDTO {
  id: UUID;
  role: Role;
  organizacion_id?: UUID;
  entidad_id?: UUID;
  ayuntamiento_id?: UUID;
  telefono: Phone;
  nombre: string;
  apellido1: string;
  apellido2: string;
  dni: DNI;
}

export const updateUser = async (
  data: UpdateUserDTO,
): Promise<Result<User, APIValidationError>> => {
  return validatedApiCall<User>(`/api/users/${data.id}`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });
};
