import Stripe from 'stripe';
import { APIValidationError } from '../../types/api/api';
import { Socio, VecinoSocio } from '../../types/socio/socio';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';
import { User } from '../../types/users/users';

export interface SocioDTO {
  organizacion_id?: UUID;
  price: Stripe.Price;
}

export type MakeSocioDTO = Socio & {
  payment_intent?: Stripe.PaymentIntent;
  subscription?: Stripe.Subscription;
};

export const registerSocio = async (
  dto: SocioDTO,
): Promise<Result<MakeSocioDTO, APIValidationError>> => {
  const data = {
    price_id: dto.price.id,
    organizacion_id: dto.organizacion_id ? dto.organizacion_id : undefined,
  };
  return validatedApiCall<MakeSocioDTO>(`/api/vecinos/socio`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};

export const getSocio = async (): Promise<
  Result<VecinoSocio, APIValidationError>
> => {
  return validatedApiCall<VecinoSocio>(`/api/vecinos/socio`, {
    method: 'GET',
    auth: true,
  });
};

export const deleteSocio = async (
  socio_id: UUID,
): Promise<Result<Socio, APIValidationError>> => {
  return validatedApiCall<Socio>(`/api/vecinos/socio/${socio_id}`, {
    method: 'DELETE',
    auth: true,
  });
};

export const cancelarSocio = async (
  user_id: UUID,
): Promise<Result<User, APIValidationError>> => {
  return validatedApiCall<User>(`/api/vecinos/socio_baja/${user_id}`, {
    method: 'DELETE',
    auth: true,
  });
};
