import styles from './languageSwitch.module.scss';
import { useTranslation } from 'react-i18next';

interface LanguageSwitchProps {
  mode?: 'home' | 'admin'; // Define los modos que soportas
}

const LanguageSwitch = ({ mode = 'home' }) => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng: string) => {
    sessionStorage.setItem('i18nextLng', lng);
    window.location.reload();
  };

  const containerClass = `${styles[`root-${mode}`]}`;
  return (
    <div className={containerClass}>
      <button
        className={`${styles.buttonSelector} ${
          i18n.language === 'es' || i18n.language === ''
            ? styles.buttonSelectorActive
            : ''
        }`}
        onClick={() => changeLanguage('es')}
      >
        <span className={styles['lg-long']}>{t('Castellano')}</span>
        <span className={styles['lg-short']}>ES</span>
      </button>
      <button
        className={`${styles.buttonSelector} ${
          i18n.language === 'ca' ? styles.buttonSelectorActive : ''
        }`}
        onClick={() => changeLanguage('ca')}
      >
        <span className={styles['lg-long']}>{t('Catalán')}</span>
        <span className={styles['lg-short']}>CA</span>
      </button>
    </div>
  );
};

export default LanguageSwitch;
