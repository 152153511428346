import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import Loading from '../components/common/Loading/Loading';
import PublicLayout from '../components/common/Layouts/PublicLayout/PublicLayout';
import LoginRestrictions from '../components/LoginRestrictions/LoginRestrictions';
import Layout from '../components/common/Layouts/Layout/Layout';
import LoginRoutes from './Login/routes';
import RegisterRoutes from './Register/routes';
import PasswordRoutes from './Auth/routes';
import DonacionRoutes from './Donaciones/routes';
import ValoracionesRoutes from './Valoraciones/routes';
import HomeRoutes from './Home/routes';
import UserRoutes from './User/routes';
import VecinosRoutes from './Vecino/routes';
import OrganizacionRoutes from './Organizacion/routes';
import EntidadRoutes from './Entidad/routes';
import FacturacionRoutes from './Facturacion/routes';
import SolicitudesRoutes from './Solicitudes/routes';
import ActividadRoutes from './Actividad/routes';
import KpisRoutes from './Kpis/routes';
import AlertsRoutes from './Alerts/routes';
import ProfileRoutes from './Profile/routes';
import NotFoundRoutes from './NotFound/routes';
import ComunicacionRoutes from './Comunicacion/routes';
import MisDonacionesRoutes from './MisDonaciones/routes';
import VoluntarioRoutes from './Voluntario/routes';
import SesionesRoutes from './Sesiones/routes';
import LegalRoutes from './Legal/routes';
import RecursosRoutes from './Recursos/routes';

const SuspenseLayout = () => (
  <Suspense fallback={<Loading />}>
    <Outlet />
  </Suspense>
);
const Index = () =>
  useRoutes([
    {
      element: <SuspenseLayout />,
      children: [
        // Public Routes
        {
          element: <PublicLayout />,
          children: [...ValoracionesRoutes, ...LegalRoutes],
        },
        {
          element: <PublicLayout variant={'form'} />,
          children: [
            ...LoginRoutes,
            ...RegisterRoutes,
            ...PasswordRoutes,
            ...DonacionRoutes.public,
          ],
        },
        // Private Routes
        {
          element: <LoginRestrictions />,
          children: [
            {
              element: <Layout />,
              children: [
                ...HomeRoutes,
                ...UserRoutes,
                ...VecinosRoutes,
                ...OrganizacionRoutes,
                ...EntidadRoutes,
                ...DonacionRoutes.private,
                ...FacturacionRoutes,
                ...SolicitudesRoutes,
                ...ActividadRoutes,
                ...KpisRoutes,
                ...ComunicacionRoutes,
                ...MisDonacionesRoutes,
                ...AlertsRoutes,
                ...ProfileRoutes,
                ...VoluntarioRoutes,
                ...SesionesRoutes,
                ...RecursosRoutes,
                ...NotFoundRoutes,
              ],
            },
          ],
        },
      ],
    },
  ]);

export default Index;
