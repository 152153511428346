import { RootState } from '../store';

/******************** ORGANIZACIÓN **************************************/
export const selectOrganizacionSlice = (state: RootState) => state.organizacion;

export const selectOrganizacion = (state: RootState) =>
  selectOrganizacionSlice(state).data;

export const selectOrganizacionErrors = (state: RootState) =>
  selectOrganizacionSlice(state).errors;

export const selectOrganizacionLoading = (state: RootState) =>
  selectOrganizacionSlice(state).loading;

/******************** LIST ORGANIZACIONES *********************************/
export const selectOrganizacionesSlice = (state: RootState) =>
  state.organizaciones;

export const selectOrganizaciones = (state: RootState) =>
  selectOrganizacionesSlice(state).data.data;

export const selectPageInfo = (state: RootState) =>
  selectOrganizacionesSlice(state).data.meta;

export const selectOrganizacionesErrors = (state: RootState) =>
  selectOrganizacionesSlice(state).errors;

export const selectOrganizacionesLoading = (state: RootState) =>
  selectOrganizacionesSlice(state).loading;

/******************** REGISTER ORGANIZACIÓN *****************************/
export const selectRegisterOrganizacion = (state: RootState) =>
  state.registerOrganizacion;

export const selectRegisterOrganizacionLoading = (state: RootState) =>
  selectRegisterOrganizacion(state)?.loading;

export const selectRegisterOrganizacionErrors = (state: RootState) =>
  selectRegisterOrganizacion(state)?.errors;

/******************** DELETE ORGANIZACIÓN *****************************/
export const selectDeleteOrganizacion = (state: RootState) =>
  state.deleteOrganizacion.selectedOrganizacion;

export const selectDeleteOrganizacionActive = (state: RootState) =>
  state.deleteOrganizacion.active;

export const selectDeleteOrganizacionLoading = (state: RootState) =>
  state.deleteOrganizacion.loading;

/******************** UPDATE ORGANIZACIÓN *****************************/

export const selectUpdateOrganizacionOrganizacion = (state: RootState) =>
  state.updateOrganizacion.organizacion;

export const selectUpdateOrganizacionActive = (state: RootState) =>
  state.updateOrganizacion.active;

export const selectUpdateOrganizacionLoading = (state: RootState) =>
  state.updateOrganizacion.loading;

export const selectUpdateOrganizacionErrors = (state: RootState) =>
  state.updateOrganizacion.errors;
