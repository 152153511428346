import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ReactComponent as IconDashboard } from '../../../../assets/asideMenu/icon_dashboard.svg';
import { ReactComponent as IconDonaciones } from '../../../../assets/asideMenu/icon_donaciones.svg';
import { ReactComponent as IconEntidad } from '../../../../assets/asideMenu/icon_entidades.svg';
import { ReactComponent as IconFacturacion } from '../../../../assets/asideMenu/icon_facturacion.svg';
import { ReactComponent as IconOrganizaciones } from '../../../../assets/asideMenu/icon_organizaciones.svg';
import { ReactComponent as IconUsers } from '../../../../assets/asideMenu/icon_users.svg';
import { ReactComponent as IconVecino } from '../../../../assets/asideMenu/icon_vecinos.svg';
import styles from '../Layout/layout.module.scss';
import { useTranslation } from 'react-i18next';

interface MenuUserProps {
  isOpenMenu: boolean;
  canListUsers: boolean;
  canListOrganizaciones: boolean;
  canListVecinos: boolean;
  canListEntidades: boolean;
  canListDonaciones: boolean;
  canViewFacturacion: boolean;
  canViewSolicitudes: boolean;
  canViewComunicaciones: boolean;
  canListActividades: boolean;
  canViewIndicadores: boolean;
  isAdmin: boolean;
  styleRoute: (route: string) => string;
}

const MenuUser: React.FC<MenuUserProps> = ({
  isOpenMenu,
  canListUsers,
  canListOrganizaciones,
  canListEntidades,
  canViewSolicitudes,
  canListDonaciones,
  canViewComunicaciones,
  canListVecinos,
  canViewFacturacion,
  canListActividades,
  canViewIndicadores,
  isAdmin,
  styleRoute,
}) => {
  const { t } = useTranslation();
  return (
    <ul className={`${styles.mainMenu} ${isAdmin ? '' : styles.isInternal}`}>
      <li className={styleRoute('/')}>
        <Link
          data-testid="dashboardLink"
          title={t('MenuInicio')}
          className={styles.menuLink}
          to="/"
        >
          <div className={styles.iconWrapper}>
            <IconDashboard className={styles.menuIcon} />
          </div>
          <span
            data-testid={
              isOpenMenu ? 'isDashboardVisible' : 'isDashboardHidden'
            }
            className={styles.menuText}
          >
            {t('MenuInicio')}
          </span>
        </Link>
      </li>
      {canListUsers && (
        <li className={`${styleRoute('/usuarios')}`}>
          <Link
            data-testid="usersLink"
            className={styles.menuLink}
            to="/usuarios"
            title={t('MenuUsuario')}
          >
            <div className={styles.iconWrapper}>
              <IconUsers className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isUsersVisible' : 'isUsersHidden'}
              className={styles.menuText}
            >
              {t('MenuUsuario')}
            </span>
          </Link>
        </li>
      )}
      {canListVecinos && (
        <li className={`${styleRoute('/vecinos')}`}>
          <Link
            data-testid="vecinosLink"
            className={styles.menuLink}
            to="/vecinos"
            title={t('MenuVecinos')}
          >
            <div className={styles.iconWrapper}>
              <IconVecino className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isVecinosVisible' : 'isVecinosHidden'}
              className={styles.menuText}
            >
              {t('MenuVecinos')}
            </span>
          </Link>
        </li>
      )}
      {canListOrganizaciones && (
        <li className={styleRoute('/organizaciones')}>
          <Link
            data-testid="organizacionesLink"
            className={styles.menuLink}
            to="/organizaciones"
            title={t('MenuOrganizaciones')}
          >
            <div className={styles.iconWrapper}>
              <IconOrganizaciones className={styles.menuIcon} />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isOrganizacionVisible' : 'isOrganizacionHidden'
              }
              className={styles.menuText}
            >
              {t('MenuOrganizaciones')}
            </span>
          </Link>
        </li>
      )}
      {canListEntidades && (
        <li className={styleRoute('/entidades')}>
          <Link
            data-testid="entidadesLink"
            className={styles.menuLink}
            to="/entidades"
            title={t('MenuEntidades')}
          >
            <div className={styles.iconWrapper}>
              <IconEntidad className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isEntidadVisible' : 'isEntidadHidden'}
              className={styles.menuText}
            >
              {t('MenuEntidades')}
            </span>
          </Link>
        </li>
      )}

      {canListDonaciones && (
        <li className={styleRoute('/donaciones')}>
          <Link
            data-testid="usersLink"
            className={styles.menuLink}
            to="/donaciones"
            title={t('MenuDonaciones')}
          >
            <div className={styles.iconWrapper}>
              <IconDonaciones className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isUsersVisible' : 'isUsersHidden'}
              className={styles.menuText}
            >
              {t('MenuDonaciones')}
            </span>
          </Link>
        </li>
      )}

      {canViewFacturacion && (
        <li className={styleRoute('/facturacion')}>
          <Link
            data-testid="facturacionLink"
            className={styles.menuLink}
            to="/facturacion"
            title={t('MenuFacturacion')}
          >
            <div className={styles.iconWrapper}>
              <IconFacturacion className={styles.menuIcon} />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isFacturacionVisible' : 'isFacturacionHidden'
              }
              className={styles.menuText}
            >
              {t('MenuFacturacion')}
            </span>
          </Link>
        </li>
      )}
      {canViewSolicitudes && (
        <li className={styleRoute('/solicitudes')}>
          <Link
            data-testid="solicitudesLink"
            className={styles.menuLink}
            to="/solicitudes"
            title={t('MenuSolicitudes')}
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'mail-bulk']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isFacturacionVisible' : 'isFacturacionHidden'
              }
              className={styles.menuText}
            >
              {t('MenuSolicitudes')}
            </span>
          </Link>
        </li>
      )}
      {/*canViewComunicaciones && (
        <li className={styleRoute('/comunicaciones')}>
          <Link
            data-testid="comunicacionesLink"
            className={styles.menuLink}
            to="/comunicaciones"
            title={t('MenuComunicaciones')}
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'paper-plane']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu
                  ? 'isComunicacionesVisible'
                  : 'isComunicacionesHidden'
              }
              className={styles.menuText}
            >
              {t('MenuComunicaciones')}
            </span>
          </Link>
        </li>
      )*/}
      {canListActividades && (
        <li className={styleRoute('/actividades')}>
          <Link
            data-testid="actividadesLink"
            className={styles.menuLink}
            to="/actividades"
            title={t('MenuActividades')}
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'project-diagram']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isActividadesVisible' : 'isActividadesHidden'
              }
              className={styles.menuText}
            >
              {t('MenuActividades')}
            </span>
          </Link>
        </li>
      )}
      {canViewIndicadores && (
        <li className={styleRoute('/indicadores')}>
          <Link
            data-testid="actividadesLink"
            className={styles.menuLink}
            to="/indicadores"
            title={t('MenuIndicadores')}
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'chart-pie']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isIndicadoresVisible' : 'isIndicadoresHidden'
              }
              className={styles.menuText}
            >
              {t('MenuIndicadores')}
            </span>
          </Link>
        </li>
      )}
    </ul>
  );
};
export default MenuUser;
