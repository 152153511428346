import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { Provincia } from '../../types/provincia/provincia';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Organizacion } from '../../types/organizaciones/organizacion';

export interface GetOrganizacionDTO {
  page?: number;
  size?: number;
  buscar_nombre?: string | '';
  buscar_poblacion?: string | '';
  provincia?: Provincia | '';
}

export interface OrganizacionesDTO {
  data: Organizacion[];
  links: LinksPage;
  meta: PageInfo;
}

export const getOrganizaciones = async (
  data: GetOrganizacionDTO,
): Promise<Result<OrganizacionesDTO, APIValidationError>> => {
  return validatedApiCall<OrganizacionesDTO>(
    `/api/organizaciones?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
