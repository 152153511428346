import { getVoluntarioAcuerdo as callGetVoluntarioAcuerdo } from '../../api/voluntarios/getVoluntarioAcuerdo';
import { VoluntarioAcuerdo } from '../../types/voluntarios/VoluntarioAcuerdo';
import createApiModule from '../createApiModule';
import { t } from '../../i18n';

const voluntarioAcuerdoModule = createApiModule({
  name: 'voluntarioAcuerdo',
  apiMethod: callGetVoluntarioAcuerdo,
  parse: (voluntarioAcuerdo) => voluntarioAcuerdo as VoluntarioAcuerdo | null,
  onSuccess: () => {},
  onError: () => ({
    message: t(
      'StoreVoluntariosVoluntarioAcuerdoModuleNoSeHaPodidoRecuperarTuAcuerdoDeVoluntario',
    ),
  }),
});

export const {
  start: getVoluntarioAcuerdo,
  success: getVoluntarioAcuerdoOk,
  error: getVoluntarioAcuerdoKo,
  loading: getVoluntarioAcuerdoLoading,
} = voluntarioAcuerdoModule.slice.actions;

export default voluntarioAcuerdoModule;
