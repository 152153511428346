import { PayloadAction } from '@reduxjs/toolkit';
import { CreatePaymentMethodData, SetupIntentResult } from '@stripe/stripe-js';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Stripe from 'stripe';
import { updateSetupIntent as callUpdateSetupIntent } from '../../../api/stripe/bridge/setupIntents';
import { setDefaultPaymentMethod as callSetDefaultPaymentMethod } from '../../../api/stripe/override/customers';
import { createSetupIntent as callCreateSetupIntent } from '../../../api/stripe/override/setupIntents';
import {
  subscribe as callSubscribe,
  SubscriptionDTO,
} from '../../../api/subscriptions/subscribe';
import {
  registerVecino as callRegisterVecino,
  RegisterVecinoDTO,
} from '../../../api/users/registerVecino';
import { convertStripeAmountToAmount } from '../../../helpers/stripeHelpers';
import { Await } from '../../../types/api/api';
import { CuotaPeriodicidad } from '../../../types/donaciones/cuotaPeriodicidad';
import { Vecino } from '../../../types/users/users';
import getStripe from '../../../utils/get-stripe';
import registerVecinoSlice, { VecinoSocio } from './registerVecinoSlice';
import {
  selectVecinoSetupIntent,
  selectVecinoSocio,
  selectVecinoVecino,
} from './selectors';
import { isApiError } from '../../../api/api';
import { t } from '../../../i18n';

function* registerVecino(
  action: PayloadAction<RegisterVecinoDTO>,
): Generator<any, void, any> {
  try {
    const result = (yield call(callRegisterVecino, action.payload)) as Await<
      ReturnType<typeof callRegisterVecino>
    >;
    switch (result.type) {
      case 'ok':
        yield put(registerVecinoSlice.actions.registerOk(result.value));
        return;
      case 'validation-error':
        yield put(registerVecinoSlice.actions.registerKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(registerVecinoSlice.actions.registerKo(e));
    }
    throw e;
  }
}

function* createSetupIntent(): Generator<any, void, any> {
  try {
    const vecino = yield select(selectVecinoVecino);
    const result = (yield call(callCreateSetupIntent, vecino.id)) as Await<
      ReturnType<typeof callCreateSetupIntent>
    >;
    switch (result.type) {
      case 'ok':
        yield put(
          registerVecinoSlice.actions.createSetupIntentOk(result.value),
        );
        return;
      case 'validation-error':
        yield put(
          registerVecinoSlice.actions.createSetupIntentKo(result.value),
        );
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(registerVecinoSlice.actions.createSetupIntentKo(e));
    }
    throw e;
  }
}

function* subscribe() {
  try {
    const vecino: Vecino | null = yield select(selectVecinoVecino);
    const vecinoSocio: VecinoSocio = yield select(selectVecinoSocio);
    if (vecino && vecinoSocio.datos_socio && vecinoSocio.datos_socio.price) {
      const subscription: SubscriptionDTO = {
        organizacion_id:
          vecinoSocio.datos_socio.socio_tipo === 'organizacion'
            ? vecino.organizacion_id
            : undefined,
        destino: vecinoSocio.datos_socio.socio_tipo,
        periodicidad: vecinoSocio.datos_socio.price.metadata[
          'periodicidad'
        ] as CuotaPeriodicidad,
        importe: convertStripeAmountToAmount(
          vecinoSocio.datos_socio.price.unit_amount,
        ),
        price: vecinoSocio.datos_socio.price,
      };
      const result = (yield call(callSubscribe, {
        user: vecino,
        subscription: subscription,
      })) as Await<ReturnType<typeof callSubscribe>>;
      switch (result.type) {
        case 'ok':
          yield put(
            registerVecinoSlice.actions.storeSuscriptionOk(result.value),
          );
          return;
        case 'validation-error':
          yield put(
            registerVecinoSlice.actions.storeSuscriptionKo(result.value),
          );
          return;
      }
    } else {
      yield put(
        registerVecinoSlice.actions.storeSuscriptionKo({
          errorType: 'validation',
          message: t('RoutesHomeHomeConfiguraMetodoPagoNoRegistrado'),
          errors: {},
        }),
      );
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(registerVecinoSlice.actions.storeSuscriptionKo(e));
    }
    throw e;
  }
}

async function confirmCard(
  setupIntent: Stripe.SetupIntent,
): Promise<SetupIntentResult> {
  const stripe = await getStripe();
  if (stripe && setupIntent.client_secret) {
    return await stripe.confirmCardSetup(setupIntent.client_secret);
  }
  return {
    error: {
      type: 'api_connection_error',
      message: t('MESSAGE_STRIPE_NO_DISPONIBLE'),
    },
  };
}

async function confirmSepaDebit(
  setupIntent: Stripe.SetupIntent,
): Promise<SetupIntentResult> {
  const stripe = await getStripe();
  if (stripe && setupIntent.client_secret) {
    return stripe.confirmSepaDebitSetup(setupIntent.client_secret);
  }
  return {
    error: {
      type: 'api_connection_error',
      message: t('MESSAGE_STRIPE_NO_DISPONIBLE'),
    },
  };
}

function* validateSetupIntentWithPaymentMethod(
  action: PayloadAction<Stripe.PaymentMethod>,
): Generator<any, void, any> {
  const setupIntent = yield select(selectVecinoSetupIntent);
  const vecino = yield select(selectVecinoVecino);
  try {
    const result = (yield call(callUpdateSetupIntent, {
      setup_intent_id: setupIntent.id,
      data: { payment_method: action.payload.id },
    })) as Await<ReturnType<typeof callUpdateSetupIntent>>;

    let confirmResult: Await<SetupIntentResult>;
    let setDefaultResult;
    switch (result.type) {
      case 'ok':
        if (action.payload.type === 'card') {
          confirmResult = yield call(confirmCard, result.value);
        } else if (action.payload.type === 'sepa_debit') {
          confirmResult = yield call(confirmSepaDebit, result.value);
        } else {
          yield put(
            registerVecinoSlice.actions.updateSetupIntentKo({
              errorType: 'unprocessable',
              message: t('MESSAGE_METODO_NO_SOPORTADO'),
            }),
          );
          return;
        }
        if (confirmResult.error) {
          yield put(
            registerVecinoSlice.actions.updateSetupIntentKo({
              errorType: 'unprocessable',
              message: confirmResult.error.message ?? '',
            }),
          );
          return;
        }
        yield put(
          registerVecinoSlice.actions.updateSetupIntentOk(
            confirmResult.setupIntent as Stripe.SetupIntent,
          ),
        );
        setDefaultResult = (yield call(callSetDefaultPaymentMethod, {
          user_id: vecino.id,
          payment_method_id: action.payload.id,
        })) as Await<ReturnType<typeof callSetDefaultPaymentMethod>>;
        if (setDefaultResult.type === 'ok') {
          yield call(subscribe);
        } else {
          yield put(
            registerVecinoSlice.actions.updateSetupIntentKo(
              setDefaultResult.value,
            ),
          );
        }
        return;
      case 'validation-error':
        yield put(
          registerVecinoSlice.actions.updateSetupIntentKo(result.value),
        );
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(registerVecinoSlice.actions.updateSetupIntentKo(e));
    }
    throw e;
  }
}

function* createPaymentMethod(
  action: PayloadAction<CreatePaymentMethodData>,
): Generator<any, void, any> {
  try {
    const stripe = yield call(getStripe);
    const { error, paymentMethod } = yield call(
      stripe.createPaymentMethod,
      action.payload,
    );
    if (error) {
      yield put(registerVecinoSlice.actions.createPaymentMethodKo(error));
      return;
    }
    yield put(registerVecinoSlice.actions.createPaymentMethodOk(paymentMethod));
    return;
  } catch (e) {
    if (isApiError(e)) {
      yield put(registerVecinoSlice.actions.createPaymentMethodKo(e));
    }
    throw e;
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    registerVecinoSlice.actions.register.type,
    registerVecino,
  ),
  takeLatest<PayloadAction<never>>(
    registerVecinoSlice.actions.createSetupIntent.type,
    createSetupIntent,
  ),
  /*takeLatest<PayloadAction<never>>(
    registerVecinoSlice.actions.confirmSetupIntent.type,
    confirmSetupIntent
  ),*/
  takeLatest<PayloadAction<never>>(
    registerVecinoSlice.actions.createPaymentMethod.type,
    createPaymentMethod,
  ),
  takeLatest<PayloadAction<never>>(
    registerVecinoSlice.actions.createPaymentMethodOk.type,
    validateSetupIntentWithPaymentMethod,
  ),
];

export default sagas;
