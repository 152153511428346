import { APIValidationError } from '../../types/api/api';
import { Organizacion } from '../../types/organizaciones/organizacion';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';

export interface UpdateOrganizacionDTO {
  id: UUID;
  entidad?: {
    id: UUID;
  };
}

export const updateOrganizacion = async (
  data: UpdateOrganizacionDTO,
): Promise<Result<Organizacion, APIValidationError>> => {
  return validatedApiCall<Organizacion>(`/api/organizaciones/${data.id}`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });
};
