import { bajaVoluntario as callBajaVoluntario } from '../../api/voluntarios/bajaVoluntario';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { getVoluntarioEstado } from './voluntarioEstadoModule';
import { t } from '../../i18n';

const voluntarioBajaModule = createApiModule({
  name: 'voluntarioBaja',
  apiMethod: callBajaVoluntario,
  onSuccess: (success) => [
    showSnackBar({
      severity: 'success',
      message: t('StoreVoluntariosVoluntarioBajaVoluntario'),
      path: '/',
    }),
    getVoluntarioEstado({}),
  ],
  onError: () => ({
    message: t(
      'StoreVoluntariosVoluntarioBajaModuleSeHaProducidoUnErrorAplicandoLaBajaDeVoluntario',
    ),
  }),
});

export const {
  start: bajaVoluntario,
  success: bajaVoluntarioOk,
  error: bajaVoluntarioKo,
  loading: bajaVoluntarioLoading,
} = voluntarioBajaModule.slice.actions;

export const { sagas } = voluntarioBajaModule;

export default voluntarioBajaModule;
